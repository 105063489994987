const Spanish = {
    JC:'ES',
    JCs:'es',
    hello: 'Hola',
    headSY: 'Página de inicio',
    headXJD:'Hoja de consulta',
    headQYCP: 'Productos de la empresa',
    headGYWM: 'Sobre nosotros',
    headLXWM: 'Contacto',
    headYSZC: 'Política de privacidad',
    headSSCP: 'Búsqueda de productos',
    headGRZX: 'Información de la cuenta',
    headYY: 'English',
    headXBYY: 'Español',
    headPTYY: 'Português',
    mainCPFL: 'Categoría de productos',
    mainTJCP: 'Productos recomendados',
    mainTJCPYH: 'Últimos productos más recomendados',
    mainCK: 'Ver Reseña',
    content:'',
    new: {
        prev: 'Previous2',
        next: 'Next2'
    },
    other:{
        cart:'Shopping Cart2',
        addedSuccess:'Added successfully!2',
        contBrowse:'Continue browsing2',
        toCart: 'Go to shopping cart2',
        cancel:'Cancel2',
        confirm:'Confirm2',
        success:'Copy Successful2',
        fail:'Copy Failed2',
        sure0:'Espere a que el administrador calcule el precio antes de confirmarlo.',
        sure1:'Are you sure you want to confirm the order?2',
        sure2:'Would you like to cancel the order?2',
        sure3:'Do you confirm the receipt of the goods?2',
        confirmOrder:'Confirm order2'

    },


    home:{
        help:'Help2',
        shop:'MolChem.shop2',
        platform:'Chemical Procurement Platform2',
        safe:'Safe and transparent2',
        place:'Please Enter CAS No. or Product Name2',
        allProducts:'All Products2',
        productsTotal:'Products Total2',
        completedTotal:'Completed Orders Total2',
        recommendation:"Today's Recommendation2",
        what:'What We Do?2',
        doWhat:'Was established with the aim to build the most comprehensive database of chemical products and contain most complete suppliers, making chemical trading more efficient, convenient and safe. facilitate global chemical trading and bring chemical buyers & sellers from all over the world to one common chemical platform.2',
        why:'Why Choose Us？2',
        why1:'Complete categories and sufficient inventory;2',
        why2:'More reasonable prices;2',
        why3:'Transparent operational procedures;2',
        hot:'Popular Products2',
        cate:'Products For Categories2',
        buy:'How To Buy2',
        language:'Language2',
        eng:'English2',
        pro:'Portuguese2',
        spa:'Spanish2',
        page:'Home Page2',
        copyright:'Copyright © 2024 MolChem.shop All rights reserved.2'


    },
    footAll: {
        rmlb: 'Productos más populares',
        fwzc: 'Política de servicio',
        sjbhzc: 'Política de protección de datos',
        yszc: 'Política de privacidad',
        gywm: 'Sobre nosotros',
        gsjs: 'Presentación de la empresa',
        lxwm: 'Contacto',
        dz: 'Dirección',
        zb: 'Sede Central',
        js: 'Introduction2',
        news: 'News & Events2'
    },
    headGRZXObj: {
        jbxx: 'Información básica',
        dzgl: 'Gestión de direcciones',
        wdsc: 'Mi inventario de productos',
        qbdd: 'Todos los pedidos',
        dqr: 'A confirmar',
        dfk: 'Por pagar',
        dfh: 'Por enviar',
        dsh: 'Por recibir',
        ywc: 'Recibido',
        yqx: 'Cancelado',
        tczh: 'Cerrar sesión',
        zhzx: 'Centro de cuentas',
        ddzx: 'Centro de pedidos',
    },
    jbXX: {
        xing: 'Apellido',
        ming: 'Nombre',
        dzyx: 'Correo electrónico',
        gs: 'Empresa',
        zw: 'Cargo',
        dhhm: 'Número de teléfono',
        tj: 'Añadir',
        mm: 'Contraseña',
        ggmm: 'Cambiar contraseña',
        ndqmm: 'Contraseña actual',
        ndxmm: 'Nueva contraseña',
        cxsrndxmm: 'Vuelva a introducir su contraseña',
        qsr: 'Por favor, entre ',
        lcbyz: 'Dos nuevas entradas de contraseña son incoherentes',

        email:'E-mail (Also Member Account)2',
        name:'Your Name2',
        country:'Country or Region2',
        corporate:'Corporate Name2',
        address:'Your Company Address2',
        contact:'Contact Information2',
        contactPlace:'Your Telphone Or Email2',
        cpwd:'Enter Current Password2',
        npwd:'Enter New Password2',
        rpwd:'Repeat Password2',
        confirm:'Confirm2'
    },
    addressObj: {
        mo: 'Establecer como dirección por defecto',
        xdz: 'nueva dirección',
        xxdz: 'Dirección detallada',
        yb: 'Código postal',
        dzgl: 'gestión de direcciones',
        mrdz: 'Dirección por defecto',
        qr: 'Confirmación',
        bjdz: 'Editar dirección',
        addAddress: 'Add Address2',
        setDefault: 'Set Default2',
        cancelDefault: 'Cancel Default2',
        edit: 'Edit2',
        editInformation: 'Edit Information2',
        shippingAddress: 'Shipping Address2',
        shipmentPort: 'Shipment Port2',
    },
    cateObj: {
        lb: 'Categoría',
        sx: 'Atributo'
    },
    goodsObj: {
        bz: 'Embalaje',
        sl: 'Cantidad',
        xzgg: 'Elegir grado',
        ljgm: 'Comprar ahora',
        zj: 'Total',
        jrgwc: 'Añadir a la cesta',
        cpxq: 'Detalles del producto',
        tjsc: 'Añadir ahorro',
        qxsc: 'Cancelar ahorro',
        buy:'Purchase2',
        toCart:'Add to Cart2',
        amountTotal: 'Amount Total12',
        buyNow: 'Buy Now12',
        moQ: 'MOQ12',
    },
    carObj: {
        sc: 'Borrar',
        qx: 'Seleccionar todo',
        cpxx: 'Información del producto',
        cpgg: 'Especificación del producto',
        dj: 'Precio unitario',
        sl: 'Cantidad',
        xj: 'Subtotal',
        gwczj: 'Total de la cesta',
        je: 'Importe',
        tj: 'Enviar',
        xz: 'Seleccione el producto eliminado',
        qrsc: 'Confirmar eliminación',
        qux: 'Cancelar',
        qr: 'Confirmar',
        xzcp: 'Seleccione un producto',
        tjdd: 'Enviar pedido',
        cpqd: 'Lista de productos',
        tjdz: 'Añadir dirección',
        qxzdz: 'Elija una dirección',

        tip:'tip2',
        noOtherPrice:'This price does not include shipping and other fees.2',
        calcPrice:'After placing the order, we will calculate the accurate price for you.2',
        confirmSA:'Confirm shipping address2',

        back:'Vuelta'
    },
    orderObj: {
        ddxx: 'Información del pedido',
        gg: 'Especificaciones',
        zt: 'Estado',
        cz: 'Operación',
        ddsj: 'Fecha del pedido',
        ddbh: 'Número de pedido',
        ckdd: 'Ver pedido',
        qrdd: 'Confirmar pedido',
        qxdd: 'Anular pedido',
        qrsh: 'Confirmar recepción de mercancías',
        zlyd: 'Volver a realizar un pedido',
        ddxq: 'Detalles del pedido',
        qm:'Nombre completo',
        dh:'Teléfono',
        wl:'Logística',
        w:'Ninguna',
        hwys:'Transporte',


        allPurOrder: 'All purchase orders2',
        searchP: 'Enter Order ID. or Product Name2',
        search: 'search2',
        orderProcess: 'Order Processing Process2',
        order1:'Price Accounting2',
        order2:'Confirm Order2',
        order3:'Payment2',
        order4:'Send out goods2',
        order5:'Waiting for delivery2',
        order6:'Confirm Receipt2',
        order_intro1: 'After generating the order,the seller calculates the total price of the order.2',
        order_intro2: 'The buyer confirms the list of goods and price information.2',
        order_intro3: 'The buyer pays the purchase price.2',
        order_intro4: 'After receiving the payment, the seller arranges for shipment.2',
        order_intro5: 'The buyer is waiting for receipt of the goods.2',
        order_intro6: 'The buyer inspects the goods and confirms receipt of the goods.2',
        filter:'Order filtering: Presenter2',
        reorder:'Reorder2',
        goodsPrice:'Price of goods2',
        otherExpenses:'Other Expenses2',
        waitAccount:'Waiting for accounting2',
        totalAmount:'Total Amount2',
        presenter:'Presenter2',
        orderID:'Order ID2',
        orderStatus:'Order Status2',
        wlIntro1:'Goods have been shipped, logistics information:2',
        wlIntro2:'We have received the payment and will ship soo.2',
        wlIntro3:'Your formal order has been generated, please confirm and pay the payment as soon as possible.2',
        shipAddress:'shipping address2',
        emailOnly:'E-mail2',
        address:'Add2',
        tariff:'Tariff2',
        tranCosts:'Transportation costs2',
        sRequire:'special requirements2',
        sRequireXq:'If there are special requirements, please fill in here.2',
    },
    logon:{
        dl:'Inicio de sesión',
        dl1:'',
        dl2:'Hacer un pedido rápidamente',
        dl3:'El acoplamiento directo tiene el mejor precio',
        dl4:'Respuesta eficiente',
        dl5:'Introduzca su dirección de correo electrónico',
        dl6:'Introduzca la contraseña',
        dl7:'Olvidar contraseña',
        dl8:'No hay cuenta? Registrarse como',
        dl9:'Introduzca el código de verificación',
        dl10:'Vuelva a introducir la contraseña',
        dl11:'Por favor, envíe el código de verificación',
        dl12:'Error de verificación',
        dl13:'Dos contraseñas introducidas no coinciden',
        dl14:'Enviar con éxito',
        dl15:'Recuperar',
        dl16:'Datos completos',
        dl17:'Introduzca sus apellidos',
        dl18:'Por favor, introduzca su nombre',
        dl19:'Introduzca su correo electrónico',
        dl20:'Por favor, introduzca el número de teléfono',
        dl21:'Por favor, introduzca el nombre de la empresa',
        dl22:'Por favor, introduzca el cargo',
        dl23:'En verificación',
        dl24:'Enviar de nuevo',
        dl25:'Enviar',
        dl26:'Recordar',
        dl27:'Fallo de verificación',
        dl28:'En verificación, espere pacientemente',
        d129:'Usuario',
        d130:'Contraseña',
        d131:'Nombre',
        d132:'Correo electrónico',
        d133:'Teléfono',
        d134:'Empresa',
        d135:'Cargo',
        d136:'Compradores',

        already:'If you are already a member of molchem.shop please login below:',
        email:'E-mail Address:',
        emailP:'Enter Your E-mail',
        enterPassword:'Enter Password',
        forgotten:'Forgotten Password?',
        account:"Don't have an account yet?",
        resetPwd:'reset password',
        resetPwdTxt:'Obtain verification code through email to reset password.',
        verCode:'Verification Code:',
        enterCode:'Enter Verification Code',
        code:'Get Code',
        local:'Local：2',
        home:'Home2',
        resetPwd1:'Reset password',
        registration:'Registration',
        registerTxt1:'Here you can register free of charge for my.chemeurope.com. With the registration you will receive your personal access to many useful features.',
        registerTxt2:'The e-mail address you enter will also be your user name.',
        registerTxt3:'By creating an account, you agree to MOLBASE’s Conditions of Use and ',
        notice:'Privacy Notice',
        register:'Register',
        anAccount:'Already have an account?',
        sign:'Sign in>',
        regSuccess:'Registered successfully!',
        completeInfo:'Please go to your member center to complete member information.',
        ok:'OK',
    },
    xjd: {
        txxjd: 'Rellenar hoja de solicitud',
        xjdlb: 'Lista de hojas de solicitud',
        xjcp: 'Productos solicitados',
        pthf: 'Plataforma de respuesta',
        lxr: 'Contacto',
        lxrxx: 'Teléfono de contacto',
        srlxr: 'Introducir contacto',
        srlxrxx: 'Introduzca el número de teléfono de contacto',
        cpsl: 'Introduzca el nombre y la cantidad del producto solicitado',
        myg:'Fecha de llegada al puerto',
        cyg:'Puerto de embarque',
        mdg:'Puerto de destino',
        ysfs:'Condiciones de pago',
        mysysj:'Condiciones comerciales',
        qxz:'Seleccione',
        xzbz:'Seleccione embalaje',
        txsl:'Rellene la cantidad',
        xzhb:'Seleccione moneda',
        cgoods:'Seleccionar producto',
        scdd:'Generar pedido',
        addd:'Añadir',
        tishi:'Recordar',
        qx:'Cancelar',
        cg:'Determinar',
        tsxx:'Esta operación borrará definitivamente el registro de modificación ¿Desea continuar?',
        sccg:'Borrado con éxito',
        qxsc:'Cancelar borrado',
        ss:'Buscar',
        xjdbh:'Consultar Número de Hoja',
        cggsmc:'Nombre de la empresa',
        cggsdz:'Dirección de la empresa',
        cgyxm:'Nombre del comprador',
        cgydh:'Teléfono del comprador',
        yjdz:'Correo electrónico',
        dj:'Precio unitario',
        zongjia:'Total',
        dw:'Unidad',
        txdw:'Rellenar Unidad',
        cp:'Producto',
    }
}
export default Spanish