<template>
  <div class="bg-fff">
    <Header />
    <!-- <div class="bread align-start font-333-18-500">
      <div>{{ $t('headSY') }}</div>
      <img :src="require('@/assets/swipe_jt_r2.png')" alt="" style="width:22px;height:22px;margin:0 20px;">
      <div class="color-2A76A8">{{ type == 1 ? $t('footAll.sjbhzc') : type == 2 ? $t('footAll.yszc') : type == 3
      ? $t('footAll.gsjs') : type == 4 ? $t('footAll.lxwm') : type == 7 ? $t('headGYWM') : '' }}</div>
    </div> -->
    <div class="flex-wrap" style="height: 60px;width: 1200px;margin: 30px auto 42px;">
      <CateList />

      <div class="flex-con align-end font-999-16 bg-fff1 p_r18">
        <div>{{ $t('logon.local') }}</div>
        <div class="pointer crumbs" @click="toMain()">{{ $t('logon.home') }}</div>
         > {{ $t('footAll.fwzc') }}
      </div>

    </div>
    <div class="flex-wrap box11 margin">
      <div class="left1">
        <Buy :num111="2" />
      </div>
      <div class="box flex-con">
        <div class="font-005BAC-24-500 align-center bold" style="margin-bottom:14px;">
          <div>{{ $t('footAll.fwzc') }} <span class="xian"></span> </div>
        </div>
        <div style="padding: 30px 35px 30px 110px;line-height: 30px;" v-html="$t('content')">
          
        </div>
        <!-- <div class="title">{{ type == 1 ? $t('footAll.sjbhzc') : type == 2 ? $t('footAll.yszc') : type == 3
          ? $t('footAll.gsjs') : type == 4 ? $t('footAll.lxwm') : type == 7 ? $t('headGYWM') : '' }}</div>
        <div v-html="$t('content')">
        </div> -->
      </div>
    </div>
    <Footer />
    <Icon />
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue'
import CateList from '@/components/CateList.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Buy from '@/components/Buy.vue'
import { getConfig } from '../api'
export default {
  components: {
    Header,
    Footer,
    Buy,
    CateList,
    Icon
  },
  data() {
    return {
    }
  },
  methods: {
    initData() {
      getConfig({
        type: 10
      }).then((data) => {
        if (data.status == 200) {
          this.$i18n.mergeLocaleMessage('en', { content: data.msg.content_en })
          this.$i18n.mergeLocaleMessage('es', { content: data.msg.content_es })
          this.$i18n.mergeLocaleMessage('tp', { content: data.msg.content_tp })
        }
      })
    },
  },
  created() {
  },
  mounted() {
    this.initData()
  },

}
</script>


<style scoped>
.bread {
  height: 45px;
  background: #f8f8f8;
  padding: 0 60px;
}

.box {
  background: #fff;
  /* padding: 0 35px 0 110px; */
  box-sizing: border-box;
}

.box11 {
  padding: 26px 20px;
  width: 1200px;
  background-color: #fff;
  box-sizing: border-box;
}

.title {
  font-size: 24px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 15px;
  box-sizing: border-box;
  text-align: center;
}

.left1 {
  width: 200px;
  /* margin-right: 30px; */
}
</style>