<template>
    <div class="bg-fff">
        <Header />

        <div class="flex-wrap" style="height: 60px;width: 1200px;margin: 30px auto 42px;">
            <CateList />

            <div class="flex-con align-end font-999-16 bg-fff1 p_r18">
                <div>{{ $t('logon.local') }}</div>
                <div class="pointer crumbs" @click="toMain()">{{ $t('logon.home') }}</div>
                > {{ addr_id ? $t('addressObj.bjdz') : $t('addressObj.xdz') }}
            </div>

        </div>
        <div class="flex-wrap box margin">
            <div class="left1">
                <MyInfo :num111="7" />
            </div>
            <div class="flex-con four-box">

                <div class="font-005BAC-24-500 align-center bold" style="margin-bottom:25px;">
                    <div>{{ addr_id ? $t('addressObj.bjdz').toUpperCase() : $t('addressObj.xdz').toUpperCase() }} <span
                            class="xian"></span> </div>
                </div>

                <!-- <div class="font-333-20-500" style="margin-bottom:12px;">
                    <div>{{ addr_id ? $t('addressObj.bjdz') : $t('addressObj.xdz') }}</div>
                </div> -->
                <div class="box1 align-between">
                    <div class="one">
                        <div class="one_title">{{ $t('xjd.cggsmc') }}:</div>
                        <input type="text" class="flex-con" :placeholder="$t('xjd.cggsmc')"
                            v-model="addressObj.addr_company" value="">
                    </div>


                    <div class="one">
                        <div class="one_title">{{ $t('xjd.cgyxm') }}:</div>
                        <input type="text" class="flex-con" :placeholder="$t('xjd.cgyxm')"
                            v-model="addressObj.addr_name" value="">
                    </div>

                    <div class="one">
                        <div class="one_title">{{ $t('addressObj.shippingAddress') }}:</div>
                        <input type="text" class="flex-con" :placeholder="$t('addressObj.shippingAddress')"
                            v-model="addressObj.addr_address" value="">
                    </div>

                    <div class="one">
                        <div class="one_title">{{ $t('xjd.cgydh') }}:</div>
                        <input type="text" class="flex-con" :placeholder="$t('xjd.cgydh')"
                            v-model="addressObj.addr_phone" value="">
                    </div>

                    <div class="one">
                        <div class="one_title">{{ $t('orderObj.emailOnly') }}:</div>
                        <input type="text" class="flex-con" :placeholder="$t('orderObj.emailOnly')"
                            v-model="addressObj.addr_postal" value="">
                    </div>

                    <div class="one">
                        <div class="one_title">{{ $t('addressObj.shipmentPort') }}:</div>
                        <input type="text" class="flex-con" :placeholder="$t('addressObj.shipmentPort')"
                            v-model="addressObj.addr_cyg" value="">
                    </div>

                    <div class="one">
                        <div class="one_title">{{ $t('xjd.mdg') }}:</div>
                        <input type="text" class="flex-con" :placeholder="$t('xjd.mdg')" v-model="addressObj.addr_mdg"
                            value="">
                    </div>

                    <div class="one">
                        <div class="one_title">{{ $t('xjd.myg') }}:</div>
                        <el-date-picker v-model="addressObj.addr_gettime" type="date" :placeholder="$t('xjd.myg')">
                        </el-date-picker>
                        <!-- <input type="text" class="flex-con" :placeholder="$t('xjd.myg')" v-model="addressObj.addr_gettime" value=""> -->
                    </div>

                    <div class="one">
                        <div class="one_title">{{ $t('xjd.mysysj') }}:</div>
                        <input type="text" class="flex-con" :placeholder="$t('xjd.mysysj')"
                            v-model="addressObj.addr_mysysj" value="">
                    </div>

                    <div class="one">
                        <div class="one_title">{{ $t('xjd.ysfs') }}:</div>

                        <el-select v-model="addressObj.addr_paytype" :placeholder="$t('xjd.ysfs')">
                            <el-option v-for="item in options" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>

                        <!-- <input type="text" class="flex-con" :placeholder="$t('xjd.ysfs')" v-model="addressObj.addr_paytype" value=""> -->
                    </div>





                </div>



                <div class="btn align-center pointer" @click="initCreateAddress">{{ $t('carObj.tj') }}</div>
            </div>
        </div>
        <Footer />
        <Icon />
    </div>
</template>

<script>
import Icon from '@/components/Icon.vue'
import CateList from '@/components/CateList.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import { createAddress, showAddress, updateAddress } from '../api'
export default {
    components: {
        Header,
        Footer,
        MyInfo,
        CateList,
        Icon
    },
    data() {
        return {
            addressObj: {},
            // checked: false,
            addr_id: this.$route.params.id,
            options: [{
                value: 'fukuantiaojian1',
                label: 'fukuantiaojian1'
            }, {
                value: 'fukuantiaojian2',
                label: 'fukuantiaojian2'
            }, {
                value: 'fukuantiaojian3',
                label: 'fukuantiaojian3'
            }]
        }
    },
    mounted() {
        if (this.addr_id) {
            this.initShowAddress()
        }
    },
    methods: {
        initShowAddress() {
            let params = {
                addr_id: this.addr_id
            }
            showAddress(params).then((data) => {
                if (data.status == 200) {
                    this.addressObj = data.msg
                    // this.checked = this.addressObj.addr_default == 1 ? true : false
                }
            })
        },
        initCreateAddress() {
            if (!this.addressObj.addr_company) {
                this.$message.error(this.$t('jbXX.qsr') + ' ' + this.$t('xjd.cggsmc'))
                return;
            }
            if (!this.addressObj.addr_name) {
                this.$message.error(this.$t('jbXX.qsr') + ' ' + this.$t('xjd.cgyxm'))
                return;
            }
            if (!this.addressObj.addr_address) {
                this.$message.error(this.$t('jbXX.qsr') + ' ' + this.$t('addressObj.shippingAddress'))
                return;
            }
            if (!this.addressObj.addr_phone) {
                this.$message.error(this.$t('jbXX.qsr') + ' ' + this.$t('xjd.cgydh'))
                return;
            }
            if (!this.addressObj.addr_postal) {
                this.$message.error(this.$t('jbXX.qsr') + ' ' + this.$t('xjd.yjdz'))
                return;
            }

            if (!this.addressObj.addr_cyg) {
                this.$message.error(this.$t('jbXX.qsr') + ' ' + this.$t('xjd.cyg'))
                return;
            }
            if (!this.addressObj.addr_mdg) {
                this.$message.error(this.$t('jbXX.qsr') + ' ' + this.$t('xjd.mdg'))
                return;
            }
            if (!this.addressObj.addr_gettime) {
                this.$message.error(this.$t('jbXX.qsr') + ' ' + this.$t('xjd.myg'))
                return;
            }
            if (!this.addressObj.addr_mysysj) {
                this.$message.error(this.$t('jbXX.qsr') + ' ' + this.$t('xjd.mysysj'))
                return;
            }
            if (!this.addressObj.addr_paytype) {
                this.$message.error(this.$t('jbXX.qsr') + ' ' + this.$t('xjd.ysfs'))
                return;
            }



            let params = {
                addr_xing: '',
                addr_default: 2,
                addr_name: this.addressObj.addr_name || '',
                addr_phone: this.addressObj.addr_phone || '',

                addr_address: this.addressObj.addr_address || '',
                addr_company: this.addressObj.addr_company || '',
                addr_postal: this.addressObj.addr_postal || '',

                addr_gettime: this.addressObj.addr_gettime,
                addr_cyg: this.addressObj.addr_cyg,
                addr_mdg: this.addressObj.addr_mdg,
                addr_paytype: this.addressObj.addr_paytype,
                addr_mysysj: this.addressObj.addr_mysysj,
            }
            if (this.addr_id) {
                params.addr_id = this.addr_id
                updateAddress(params).then((data) => {
                    if (data.status == 200) {
                        this.$message.success(data.msg)
                        setTimeout(() => {
                            this.$router.go(-1)
                        }, 800);
                    }
                })
                return
            }
            createAddress(params).then((data) => {
                if (data.status == 200) {
                    this.$message.success(data.msg)
                    setTimeout(() => {
                        this.$router.go(-1)
                    }, 800);
                }
            })
        },
    }

}
</script>


<style scoped>
input[type='checkbox'] {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 1px;
    margin: 0;
    margin-right: 12px;
}

input[type='checkbox']:checked {
    border: 1px solid #018ECF;
    background: url('@/assets/checked1.png') no-repeat center center;
    background-size: 18px 18px;
}

.four-box {
    padding: 0 86px 0 132px;
}

.left1 {
    width: 200px;
    /* margin-right: 30px; */
}

.box {
    padding: 26px 20px;
    width: 1200px;
    background-color: #FFFFFF;
    box-sizing: border-box;
}

.box1 {
    display: flex;
    flex-wrap: wrap;
}

.one {
    width: calc((100% - 40px)/2);
    /* box-sizing: border-box;
    width: 100%;
    height: 52px;
    border-radius: 2px;
    border: 1px solid #DDDDDD;
    margin-bottom: 18px;
    align-items: center; */
}

.kuang {
    width: 60px;
}

.icon {
    display: block;
    width: 36px;
    height: 36px;
    margin: 8px auto;
}

input[type='text'],
input[type='password'],
input[type='tel'] {
    background: rgba(0, 0, 0, 0);
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    padding: 0 11px;
    color: #333;
    font-size: 16px;
    outline: none;
    height: 42px;
    width: 100%;
    margin-bottom: 15px;
}

input[type='text']::-webkit-input-placeholder,
input[type='password']::-webkit-input-placeholder,
input[type='tel']::-webkit-input-placeholder {
    color: #ccc;
    font-weight: 400;
}

/* .xian {
    width: 1px;
    height: 37px;
    border-left: 1px solid #DDDDDD;
} */

.btn {
    font-size: 18px;
    color: #FFFFFF;
    width: 240px;
    height: 48px;
    background: #005BAC;
    border-radius: 24px;
    margin: 50px auto 0;
}

.one_title {
    font-size: 16px;
    color: #005BAC;
    line-height: 47px;
}

::v-deep .el-input__inner::-webkit-input-placeholder {
    color: #ccc
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
    font-size: 16px;
    margin-bottom: 15px;
}

::v-deep .el-input__inner {
    -webkit-appearance: none;
    background-color: #FFF;
    background-image: none;
    border-radius: 0;
    border: 1px solid #eee;
    box-sizing: border-box;
    color: #333;
    display: block;
    height: 42px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    width: 100%;
}

::v-deep .el-input__inner:hover {
    border-color: #eee
}

::v-deep .el-input.is-active .el-input__inner,
::v-deep .el-input__inner:focus {
    border-color: #eee;
    outline: 0
}


::v-deep .el-select{
    margin-bottom: 15px;
    display: block;
}
::v-deep .el-select .el-input__inner:focus,::v-deep .el-select:hover .el-input__inner{border-color:#eee}
::v-deep .el-select .el-input.is-focus .el-input__inner{border-color:#eee}

::v-deep .el-select-dropdown__item.selected{color:#333 !important;font-weight:700}
::v-deep .el-select .el-input{
    font-size: 16px;
}




</style>