<template>
  <div>
    <Header />

    <div class="box">

      <div class="flex-wrap" style="height: 60px;width: 1200px;margin: 0 auto 50px;">
        <CateList />

        <div class="flex-con align-end font-999-16 bg-fff1 p_r18">
          <div>{{ $t('logon.local') }}</div>
          <div class="pointer crumbs" @click="toMain()">{{ $t('logon.home') }}</div>
           > {{ $t('logon.dl') }}
        </div>

      </div>

      <div class="flex-wrap" style="width: 1200px;margin: 0 auto;">
        <div class="flex-con"></div>


        <div class="right_box">

          <div class="font-005BAC-24-500 align-center bold" style="margin-bottom:30px;">
            <div>{{ $t('logon.dl').toUpperCase() }} <span class="xian"></span> </div>
          </div>



          <div style="margin-bottom: 2px;" class="font-666-14">{{ $t('logon.already') }}</div>

          <div style="margin: 34px 0 20px;" class="font-005BAC-16">{{ $t('logon.email') }}</div>

          <input type="text" v-model="email" class="flex-con" :placeholder="$t('logon.emailP')" value="">

          <div style="margin: 34px 0 20px;" class="font-005BAC-16">{{ $t('logon.d130') }}</div>

          <input type="password" v-model="pwd" class="flex-con" :placeholder="$t('logon.enterPassword')" value="">


          <div class="pointer font-005BAC-16 m_b15 m_t30" @click="fnForgetPwd">{{ $t('logon.forgotten') }}</div>
          <div class="pointer font-005BAC-16" @click="fnRegister">{{ $t('logon.account') }}</div>
          <div class="btn pointer align-center" @click="toLogin">{{ $t('logon.dl') }}</div>


        </div>
      </div>


    </div>
    <Footer />
  </div>
</template>

<script>

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import CateList from '@/components/CateList.vue'
import { mrLogin } from '../api'
export default {
  components: {
    Header,
    Footer,
    CateList
  },
  data() {
    return {
      email: '',
      pwd: '',
    }
  },
  methods: {
    toLogin() {
      if (this.email == '') {
        this.$message.error(this.$t('logon.dl5'))
        return;
      }
      if (this.pwd == '') {
        this.$message.error(this.$t('logon.dl6'));
        return;
      }
      mrLogin({
        email: this.email,
        pwd: this.pwd,
      }).then((data) => {
        if (data.status == 200) {
          localStorage.setItem('allUserMr', JSON.stringify(data.msg))
          if (data.msg.user_type == 1) {
            this.$router.push({ name: 'main' })
          } else {
            this.$router.push({
              name: 'myInfo'
            })
          }

        }
      })

    },
    fnRegister() {
      this.$router.push({
        name: 'register'
      })
      // window.open(this.$router.resolve({ name: 'register' }).href, '_blank');

    },
    fnForgetPwd() {
      this.$router.push({
        name: 'forgetPwd'
      })
      // window.open(this.$router.resolve({ name: 'forgetPwd' }).href, '_blank');
    },
  },
  mounted() {

  },

}
</script>


<style scoped>
.box {
  min-height: 938px;
  background: url('@/assets/home/bg_login.png') no-repeat center center;
  background-size: 100% 100%;
  padding-top: 30px;
}




.head_contain {
  width: 100%;
  min-height: 110px;
  font-size: 16px;
  line-height: 22px;
  color: #008dd1;
}

.head_top {
  background-color: #fff;
  height: 90px;
  padding: 0 60px;
}

.head_top>div,
.head_top>img {
  margin-left: 38px;
}

.logo {
  width: 269px;
  height: 56px;
  margin-left: 60px;
  margin-top: 70px;
}

.head_tab {
  height: 42px;
  background: #008dd1;
  padding: 0 60px;
  color: #fff;
  font-weight: normal;
}


.title {
  font-size: 22px;
  font-weight: 400;
  color: #333333;
  line-height: 15px;
  text-align: left;
  margin-bottom: 30px;
}

.info {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  line-height: 21px;
  text-align: center;
  margin-bottom: 50px;
}

.tu1 {
  display: block;
  width: 75%;
  height: 114px;
  margin: 22px auto 0;
}

/* .xian {
  width: 1px;
  border-left: 1px solid #dddddd;
} */

.right_box {
  width: 661px;
  height: 568px;
  background: #FFFFFF;
  padding: 20px 70px 0 50px;
  box-sizing: border-box;

}

.one {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #dddddd;
  margin-bottom: 18px;
}

.kuang {
  width: 65px;
  border-right: 1px solid #dddddd;
}

.icon {
  display: block;
  width: 36px;
  height: 36px;
  margin: 6px auto;
}

input[type="text"],
input[type="password"],
input[type="tel"] {
  width: 100%;
  background: rgba(0, 0, 0, 0);
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  height: 43px;
  padding: 0 16px;
  color: #333;
  font-size: 16px;
  outline: none;
}

input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder {
  color: #ccc;
}

.getCode {
  line-height: 40px;
  width: 113px;
  background: #018ecf;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}



.btn {
  width: 240px;
  height: 48px;
  background: #005BAC;
  border-radius: 24px;
  font-size: 18px;
  color: #FFFFFF;
  margin: 50px auto 0;
}

.icon1 {
  display: block;
  width: 25px;
  height: 25px;
  margin-right: 16px;
}



.leftTxt {
  font-size: 15px;
  font-weight: 400;
  color: #333333;
  width: 75%;
  margin: auto;
}

.oneTxt {
  align-items: center;
}

.bottom {
  font-size: 15px;
  font-weight: 400;
  color: #666666;
  margin-top: 30px;
}

.left {
  width: 1400px;
  height: 100vh;
  background-image: url('@/assets/loginbg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.el-dropdown {
  font-size: 18px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #999;
}

.el-icon-arrow-down {
  font-size: 18px;
}
</style>