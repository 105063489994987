<template>
  <div style="background-color: #fff;">
    <Header />
    <div class="align-center">
      
      <div class="box flex-wrap">
        <img src="../assets/404.png" class="img404" alt="">
        <div class="flex-con align-center2">
          <div class="txt1">503</div>
          <div class="txt2">Website is Maintaining</div>
          <div class="txt3 align-center pointer" @click="toMain()">homepage</div>
        </div>
      </div>
      
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
    }
  },
  methods: {
  },
  created() {
  },
  mounted() {
  },

}
</script>


<style scoped>
.img404{
  width: 444px;
  height:444px;
  margin-right: 40px;
}

.box {
  background: #fff;
  box-sizing: border-box;
  /* margin: 200px auto; */
}

.txt1{
  font-size: 49px;
color: #005BAD;line-height: 67px;
}

.txt2{
  font-size: 18px;
color: #333333;
line-height: 25px;
margin: 20px 0 35px 0;
}

.txt3{
  font-size: 18px;
color: #FFFFFF;
width: 135px;
height: 43px;
background: #005BAD;
border-radius: 4px;
}
</style>