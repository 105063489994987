<template>
    <div class="bg-fff">
        <Header />
        <div class="flex-wrap" style="height: 60px;width: 1200px;margin: 30px auto 42px;">
            <CateList />

            <div class="flex-con align-end font-999-16 bg-fff1 p_r18">
                <div>{{ $t('logon.local') }}</div>
                <div class="pointer crumbs" @click="toMain()">{{ $t('logon.home') }}</div>
                 > {{ $t('headGRZXObj.wdsc') }}
            </div>

        </div>
        <div class="flex-wrap box margin">
            <div class="left1">
                <MyInfo :num111="8" />
            </div>
            <div class="flex-con four-box">
                <div class="font-005BAC-24-500 align-center bold" style="margin-bottom:46px;">
                    <div>{{ $t('headGRZXObj.wdsc').toUpperCase() }} <span class="xian"></span> </div>
                    <!-- <div class="font-999-18-500">edit</div> -->
                </div>
                <div class="four-out">
                    <div class="four pointer relative" v-for="(item, index) in goods"
                        :key="'goods_' + item.goods_id + '_' + index">
                        <div class="f_tou flex-wrap">
                            <div class="font-333-16 flex-con" style="line-height: 36px;background: #FFC800;">
                                <div class="font-333-16-500" style="padding-left: 12px;line-height: 36px;" v-show="user_id">
                                    {{ $t('JC') }}
                                    <!-- <span class="through color-999 normal">$ {{ item.goods_price || '' }}</span> -->
                                    $ {{ item.goods_cu_price || '' }} / {{ item.goods_unit || '' }}
                                </div>
                            </div>
                            <div class="f_collect align-center" @click='cancleCollect(item.goods_id)'><el-image
                                    fit="cover" :src="require('@/assets/star1.png')" class="f_collect_img"></el-image>
                            </div>
                        </div>
                        <!-- <input type="checkbox" name="collect" checked @click.stop="() => { }"
                            @click='(e) => { cancleCollect(e, item.goods_id) }' /> -->
                        <div class="hover_all">
                            <div class="fourImg_out">
                                <div class="relative">
                                    <el-image fit="cover" :src="item.goods_thumb" class="fourImg"></el-image>
                                    <div class="font-fff-16 product-intro align-center1 center">
                                        {{ $i18n.locale == 'en' ? item.goods_intro_en
                                            : $i18n.locale == 'es' ? item.goods_intro_es : item.goods_intro_tp }}
                                    </div>
                                </div>

                            </div>
                            <div class="aui-ellipsis-1 goods_name">
                                {{ $i18n.locale == 'en' ? item.goods_name_en
                                    : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}
                            </div>

                        </div>
                        <div class="align-center goods_btn">
                            <!-- <div @click.stop="dialogVisible = true">{{ $t('goodsObj.toCart') }}</div>
                            <div> &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; </div> -->
                            <div @click="fnGoodsDetails(item.goods_id)">{{ $t('goodsObj.buy') }}</div>
                        </div>
                        <!-- <div class="btn1 align-center m_t6 margin" @click="fnGoodsDetails(item.goods_id)">{{
                            $t('mainCK') }}</div> -->
                    </div>
                </div>  
                <div class="align-center m_t12" v-show="total > 0">
                    <Page :limit="limit" :totalNum="total" @onPage="changePage" />
                </div>
            </div>
        </div>
        <el-dialog :title="$t('carObj.tip')" :visible.sync="dialogVisible" width="30%" center :show-close="false" append-to-body>
            <span>{{ $t('other.addedSuccess') }}</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="mini">{{ $t('other.contBrowse') }}</el-button>
                <el-button type="primary" @click="fnCar" size="mini">{{ $t('other.toCart') }}</el-button>
            </span>
        </el-dialog>
        <Footer />
        <Icon />
    </div> 
</template>

<script>
import Icon from '@/components/Icon.vue'
import CateList from '@/components/CateList.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import Page from '@/components/Page.vue'
import { limit, myCollect, doCollect } from '../api'
export default {
    components: {
        Header,
        Footer,
        MyInfo,
        Page,
        CateList,
        Icon
    },
    data() {
        return {
            page: 1,
            limit: limit,
            total: 0,
            goods: [],
            dialogVisible:false,
            user_id: localStorage.getItem("allUserMr")?JSON.parse(localStorage.getItem("allUserMr")).user_id:0,
        }
    },
    mounted() {
        this.initMyCollect()
    },
    methods: {
        fnCar() {
            this.$router.push({
                name: 'car'
            })
        },
        cancleCollect(idd) {
            let params = {
                goods_id: idd
            }
            doCollect(params).then((data) => {
                if (data.status == 200) {
                    this.$message.success(data.msg)
                    this.initMyCollect();
                }
            })
        },
        initMyCollect() {
            let params = {
                page: this.page
            }
            myCollect(params).then((data) => {
                if (data.status == 200) {
                    this.goods = data.msg
                    // this.total = data.count
                }
            })
        },
        changePage(childrenData) {
            this.page = childrenData;
            this.initMyCollect();
        },
    }

}
</script>


<style scoped>
input[type='checkbox'] {
    position: absolute;
    right: 12px;
    top: 12px;
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid #979797;
    border-radius: 2px;
    z-index: 1;
}

input[type='checkbox']:checked {
    border: 2px solid #018ECF;
    background: url('@/assets/checked1.png') no-repeat center center;
    background-size: 18px 18px;
}

.four-box {
    /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); */
    padding: 0 72px 22px 110px;
}

.four-out {
    display: flex;
    flex-wrap: wrap;
}

.fourImg {
    display: block;
    width: 100%;
    height: 150px;
    /* margin: 0 auto 6px; */
    transition: all 0.3s;
}



.fourImg_out {
    padding: 10px 12px;
}
 
.four-out .four {
    margin-right: 45px; 
    margin-bottom: 50px;
    position: relative;
    display: inline-block;
    width: calc((100% - 90px)/3);
    /* background: #F8F8F8; */
    box-sizing: border-box;
    /* padding: 6px 0 12px; */
    transition: all 0.3s;
    height: 300px;
    border: 1px solid #EEEEEE;
}

.f_tou {
    height: 36px;
    line-height: 36px;
}

.f_collect {
    width: 36px;
}

.f_collect_img {
    width: 24px;
    height: 24px;
}

.four-out .four:nth-child(3n) {
    margin-right: 0;
}

.btn1 {
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 25px;
    height: 34px;
    background: #008DD1;
    border-radius: 17px;
    width: 208px;
    transition: all 0.3s;
    display: none;
}

.hover_all:hover .product-intro {
    display: inline-flex;
}

.hover_all:hover .goods_name {
    background: #005BAC;
    color: #fff;
}

/* .four:hover .fourImg {
    display: none;
}

.four:hover .btn1 {
    display: block;
} */

.product-intro {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    height: 100%;
    /* height: 40%; */
    overflow-y: scroll;
    display: none;
    /* margin-top: 20px; */
    padding: 0 20px;
    transition: all 0.3s;
    background: rgba(0, 0, 0, 0.5);
}

.product-intro::-webkit-scrollbar {
    display: none;
}

.collect {
    display: none;
    width: 28px;
    height: 28px;
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 1;
}

.left1 {
    width: 200px;
    /* margin-right: 30px; */
}

.box {
    padding: 26px 20px;
    width: 1200px;
    box-sizing: border-box;
    background-color: #fff;
}

.goods_name {
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: #F3F3F3;
    font-size: 16px;
    color: #333333;
}

.goods_btn {
    line-height: 58px;
    font-size: 16px;
    color: #666;
}

.goods_btn div:not(:nth-child(2)):hover {
    color: #005BAC;
}

::v-deep .el-button--primary {
    color: #FFF;
    background-color: #005BAC;
    border-color: #005BAC;
}

::v-deep .el-button--primary:focus,
::v-deep .el-button--primary:hover {
  background-color: #005BAC;
  border-color: #005BAC;
}    

::v-deep .el-button--default:hover {
  border: 1px solid #DCDFE6; 
  color: #606266; 
  background-color: #fff;
}

</style>