<template>
    <div class="relative" @mouseenter="mouseenterOutFl()" @mouseleave="mouseleaveOutFl()">
        <div class="align-between bg-005BAC border-box" style="padding:0 18px 0 15px;height: 60px;width:276px;">
            <div class="align-center">
                <img :src="require('@/assets/home/menu.png')" fit="cover" alt=""
                    style="width: 24px;height: 24px;margin-right: 18px;" />
                <div class="font-fff-18-bold">{{ $t('home.allProducts') }}</div>
            </div>
            <img :src="require('@/assets/home/jt_b.png')" fit="cover" alt="" style="width: 16px;height: 16px;" />

        </div>

        <div class="meng1" v-show="cateShow">

        </div>
        <div class="tab1_menu_out" style="z-index: 999;" @mouseenter="mouseenterOut()" @mouseleave="mouseleaveOut()"
            v-show="cateShow">
            <div class="tab1_menu">
                <div class="menu_item align-center2" @mouseenter="mouseenter(index, item)"
                    @mouseleave="mouseleave(index, item)" v-for="(item, index) in goodsCate" :key="'first_' + index"
                    >
                    <div class="cate1_txt pointer" @click.stop="fnToCate(1)">{{ $i18n.locale == 'en' ? item.cate_name_en
                        : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp }}</div>
                    <div class="aui-ellipsis-1">
                        <span class="cate2_txt pointer" v-for="(item, index) in item.secondGoodsCate"
                            :key="'second_all_' + index" @click.stop="fnToCate(2, item.cate_id, $i18n.locale == 'en' ? item.cate_name_en
                                : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp)">
                            {{ $i18n.locale == 'en' ?
                                item.cate_name_en
                                : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp }} <span class="font-999-12"
                                style="margin:0 3px;">|</span>
                        </span>
                    </div>
                </div>
            </div>


            <div class="item_hover" v-show="showHover">
                 <div class="item_hover_left">
                    <span class="pointer second_txt" v-for="(item, index) in secondGoodsCate"
                        :key="'second_all_' + index" @click="fnToCate(2, item.cate_id, $i18n.locale == 'en' ? item.cate_name_en
                            : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp)">
                            <span class="third_txt">
                                {{ $i18n.locale == 'en' ?
                            item.cate_name_en
                            : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp }}
                            </span>
                       
                        <span class="font-999-12" style="margin:0 3px;position: relative;top: -2px;">|</span>&nbsp;
                    </span>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import { goodsCateList } from "../api";
export default {
    data() {
        return {
            cateShow: false,
            isHover: -1,
            goodsCate: [],
            secondGoodsCate: [],
            first_cate_id: "",
            first_cate_name: "",
            showHover: false,
        }
    },
    mounted() {
        this.initCate()
    },
    methods: {
        fnToCate(type, cate_id1, cate_id1_name, cate_id2, cate_id2_name) {
            //type==1 cate_id1为一级分类id,type==2 cate_id1为二级分类id,type==3 cate_id1为二级分类id cate_id2为三级分类id
            // console.log(type + '||' + this.$router.history);
            if (this.$router.history.current.name == 'cate') {
                this.$bus.$emit('cate', {
                    id1: this.first_cate_id,
                    id2: cate_id1,
                    id3: cate_id2,
                    id1_name: this.first_cate_name,
                    id2_name: cate_id1_name,
                    id3_name: cate_id2_name
                })
                this.cateShow = false;
            } else {
                this.$router.push({
                    name: 'cate',
                    params: {
                        id1: this.first_cate_id,
                        id2: cate_id1,
                        id3: cate_id2,
                        id1_name: this.first_cate_name,
                        id2_name: cate_id1_name,
                        id3_name: cate_id2_name
                    }
                })
            }
        },
        initCate() {
            let params = {}
            goodsCateList(params).then((data) => {
                if (data.status == 200) {
                    this.goodsCate = data.msg
                    console.log(data.msg);
                }
            })
        },
        mouseenter(index, item) {
            this.isHover = index;
            this.first_cate_id = item.cate_id;

            this.first_cate_name = this.$i18n.locale == 'en' ? item.cate_name_en
                : this.$i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp
            this.secondGoodsCate = this.goodsCate[index].secondGoodsCate
        },
        mouseleave(index, item) {
            this.isHover = -1;
        },
        mouseenterOut() {
            this.showHover = true;
        },
        mouseleaveOut() {
            this.showHover = false;
            this.cateShow = false;
        },
        mouseenterOutFl() {
            this.cateShow = true;
        },
        mouseleaveOutFl() {
            this.cateShow = false;
        },

    }
}
</script>

<style scoped>
.item_hover_left {
    max-width: 520px;
    overflow-y: scroll;
    padding: 20px 0;
    line-height: 23px;
}


.item_hover {
    height: 400px;
    width: 520px;
    background: #fff;
    box-sizing: border-box;
    position: absolute;
    left: 276px;
    top: 0;
    padding: 20px 40px;
    z-index: 99;
}

.tab1_menu_out {
    position: absolute;
    top: 60px;
    left: 0;
    width: 276px;
    height: 400px;
    display: block;
    z-index: 0;
}

.allHover:hover .tab1_menu_out {
    display: block;
}

.tab1_menu {
    width: 276px;
    height: 400px;
    background: #F3F3F3;
    box-sizing: border-box;
    padding: 14px 7px 28px 10px;
    z-index: 99;
    overflow-y: scroll;
}

/* .tab1_menu::-webkit-scrollbar {
  display: none;
} */

.tab1_menu::-webkit-scrollbar,
.item_hover_left::-webkit-scrollbar {
    width: 3px;
    border-radius: 2px;
}

.tab1_menu::-webkit-scrollbar-button,
.item_hover_left::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
    display: none;
}

.tab1_menu::-webkit-scrollbar-corner,
.item_hover_left::-webkit-scrollbar-corner {
    background-color: transparent;
}

.tab1_menu::-webkit-scrollbar-thumb,
.item_hover_left::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: #005BAC;
}

.menu_item {
    padding-left: 8px;
    border-bottom: 1px dotted #ccc;
    height: 60px;
}

.cate1_txt {
    font-size: 18px;
    color: #333;
    margin-bottom: 8px;
}

.cate1_txt:hover {
    color: #005BAC;
}

.cate2_txt {
    font-size: 12px;
    color: #333;
}

.cate2_txt:hover {
    color: #317EE7;
}

.third_txt:hover {
    color: #317EE7;
}

.meng1 {
    width: 6000px;
    height: 436px;
    background: rgba(0, 0, 0, 0.45);
    position: absolute;
    margin: auto;
    left: -2000px;
    z-index: 97;
}
</style>
