<template>
    <div class="bg-fff">
        <Header />
        <div class="flex-wrap" style="height: 60px;width: 1200px;margin: 30px auto 42px;">
      <CateList />

      <div class="flex-con align-end font-999-16 bg-fff1 p_r18">
        <div>{{ $t('logon.local') }}</div>
        <div class="pointer crumbs" @click="toMain()">{{ $t('logon.home') }}</div>
         > {{ $t('headGRZXObj.jbxx') }}
      </div>

    </div>
        <div class="flex-wrap box margin">
            <div class="left1">
                <MyInfo :num111="6" />
            </div>
            <div class="flex-con four-box">
                <div class="box1">
                    <div class="font-005BAC-24-500 align-center bold" style="margin-bottom:45px;">
                        <div>{{ $t('headGRZXObj.jbxx').toUpperCase() }} <span class="xian"></span> </div>
                    </div>

                    <div class="info_txt">
                        <div>{{ $t('jbXX.email') }}:</div>
                    </div>
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con"
                            v-model="baseInfo.user_email" value="" readonly>
                    </div>
                    <div class="info_txt">
                        <div>{{ $t('jbXX.name') }}:</div>
                    </div>
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con" :placeholder="$t('jbXX.name')"
                            v-model="baseInfo.user_name" value="">
                    </div>
                    <div class="info_txt">
                        <div>{{ $t('jbXX.gs') }}:</div>
                    </div>
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con" :placeholder="$t('jbXX.corporate')"
                            v-model="baseInfo.user_company" value="">
                    </div>
                    <div class="info_txt">
                        <div>{{ $t('jbXX.country') }}:</div>
                    </div>
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con" :placeholder="$t('jbXX.country')"
                        v-model="baseInfo.user_country"  value="">
                    </div>
                    <div class="info_txt">
                        <div>{{ $t('footAll.dz') }}:</div>
                    </div>
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con" :placeholder="$t('jbXX.address')"
                        v-model="baseInfo.user_address"   value=""> 
                    </div>
                    <div class="info_txt">
                        <div>{{ $t('jbXX.contact') }}:</div>
                    </div>
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con" :placeholder="$t('jbXX.contactPlace')"
                            v-model="baseInfo.user_phone" value="">
                    </div>
                    <div class="btn pointer" @click="initCommitCheck">{{ $t('carObj.tj') }}</div>


                    <div class="font-005BAC-24-500 align-center bold" style="margin-bottom:20px;">
                        <div>{{ $t('jbXX.ggmm').toUpperCase() }} <span class="xian"></span> </div>
                    </div>
                    <div class="info_txt">
                        <div>{{ $t('jbXX.ndqmm') }}:</div>
                    </div>
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con" :placeholder="$t('jbXX.cpwd')"
                            v-model="pwdObj.user_password" value="">
                    </div>
                    <div class="info_txt">
                        <div>{{ $t('jbXX.ndxmm') }}:</div>
                    </div>
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con" :placeholder="$t('jbXX.npwd')" v-model="pwdObj.pwd"
                            value="">
                    </div>
                    <div class="info_txt">
                        <div>{{ $t('jbXX.rpwd') }}:</div>
                    </div>
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con" :placeholder="$t('jbXX.rpwd')"
                            v-model="pwdObj.verifyPwd" value="">
                    </div>
                    <div class="btn pointer" style="margin-bottom: 0;" @click="initChangePwd">{{ $t('jbXX.confirm') }}</div>
                </div>
            </div>
        </div>
        <Footer />
        <Icon />
    </div>
</template>

<script>
import Icon from '@/components/Icon.vue'
import CateList from '@/components/CateList.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import { userBaseInfo, commitCheck, changePwd } from '../api'
export default {
    components: {
        Header,
        Footer,
        MyInfo,
        CateList,
        Icon
    },
    data() {
        return {
            baseInfo: {},
            pwdObj: {}
        }
    },
    mounted() {
        this.initBaseInfo()
    },
    methods: {
        initBaseInfo() {
            let params = {}
            userBaseInfo(params).then((data) => {
                if (data.status == 200) {
                    this.baseInfo = data.msg
                }
            })
        },
        initCommitCheck() {
            if (!this.baseInfo.user_name) {
                this.$message.error(this.$t('jbXX.qsr') +' '+ this.$t('jbXX.name'))
                return;
            }

            // if (!this.baseInfo.user_email) {
            //     this.$message.error(this.$t('jbXX.qsr') + this.$t('jbXX.dzyx'))
            //     return;
            // }
            if (!this.baseInfo.user_company) {
                this.$message.error(this.$t('jbXX.qsr') +' '+ this.$t('jbXX.gs'))
                return;
            }
            if (!this.baseInfo.user_country) {
                this.$message.error(this.$t('jbXX.qsr') +' '+ this.$t('jbXX.country'))
                return;
            }
            if (!this.baseInfo.user_address) {
                this.$message.error(this.$t('jbXX.qsr') +' '+ this.$t('jbXX.address'))
                return;
            }
            if (!this.baseInfo.user_phone) {
                this.$message.error(this.$t('jbXX.qsr') +' '+ this.$t('jbXX.contactPlace'))
                return;
            }

            let params = {
                user_email: this.baseInfo.user_email || '',
                // user_xing: this.baseInfo.user_xing || '',
                user_name: this.baseInfo.user_name || '',
                user_phone: this.baseInfo.user_phone || '',
                user_company: this.baseInfo.user_company || '',
                // user_position: this.baseInfo.user_position || '',
                user_country: this.baseInfo.user_country || '',
                user_address: this.baseInfo.user_address || '',
                
            }
            commitCheck(params).then((data) => {
                if (data.status == 200) {
                    this.$message.success(data.msg)
                    this.$bus.$emit("userName", this.baseInfo.user_name);
                    let allUserMr=JSON.parse(localStorage.getItem('allUserMr'))

                    allUserMr.user_name=this.baseInfo.user_name

                    localStorage.setItem('allUserMr', JSON.stringify(allUserMr))
                    

                }
            })
        },
        initChangePwd() {
            if (!this.pwdObj.user_password) {
                this.$message.error(this.$t('jbXX.qsr') +' '+ this.$t('jbXX.ndqmm'))
                return;
            }
            if (!this.pwdObj.pwd) {
                this.$message.error(this.$t('jbXX.qsr') +' '+ this.$t('jbXX.ndxmm'))
                return;
            }
            if (!this.pwdObj.verifyPwd) {
                this.$message.error(this.$t('jbXX.qsr') +' '+ this.$t('jbXX.cxsrndxmm'))
                return;
            }
            if (this.pwdObj.verifyPwd != this.pwdObj.pwd) {
                this.$message.error(this.$t('jbXX.lcbyz'))
                return;
            }
            let params = {
                oldPwd: this.pwdObj.user_password || '',
                pwd: this.pwdObj.pwd || '',
                verifyPwd: this.pwdObj.verifyPwd || ''
            }
            changePwd(params).then((data) => {
                if (data.status == 200) {
                    this.$message.success(data.msg)
                    this.pwdObj={}
                }
            })
        },
    }

}
</script>


<style scoped>
.left1 {
    width: 200px;
}

.box {
    padding: 26px 20px 70px;
    width: 1200px;
    box-sizing: border-box;
    background-color: #fff;
}

.box1 {
    padding: 0 210px;
}

.one {
    width: 100%;
    height: 42px;
    border-radius: 2px;
    border: 1px solid #eee;
    align-items: center;
    margin-bottom: 10px;
}

.kuang {
    width: 60px;
}

.icon {
    display: block;
    width: 36px;
    height: 36px;
    margin: 8px auto;
}

input[type='text'],
input[type='password'],
input[type='tel'] {
    background: rgba(0, 0, 0, 0);
    border: none;
    box-sizing: border-box;
    padding: 0 16px;
    color: #333;
    font-size: 16px;
    /* font-weight: 500; */
    outline: none;
}

input[type='text']::-webkit-input-placeholder,
input[type='password']::-webkit-input-placeholder,
input[type='tel']::-webkit-input-placeholder {
    color: #ccc;
    font-weight: 400;
}

/* .xian {
    width: 1px;
    height: 37px;
    border-left: 1px solid #DDDDDD;
} */

.btn {
    font-size: 18px;
    color: #FFFFFF;
    line-height: 48px;
    text-align: center;
    background: #005BAC;
border-radius: 24px;width: 240px;
height: 48px;
margin: 64px auto 90px;
}

.info_txt {
    font-size: 16px;
    color: #005BAC;
    line-height: 50px;
}
</style>