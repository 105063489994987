<template>
    <div class="align-center1 right_icon111">
        <div class="relative" style="margin-bottom: 13px;" v-show="user_id&&name1!='car'">
            <el-image style="width: 62px; height: 62px;" @click='fnCar' class="pointer"
                :src="require('@/assets/car.png')" fit="cover"></el-image>
            <div class="car_num align-center" v-show="shopCarNumber111">{{ shopCarNumber111 || 0 }}</div>
        </div>
        <el-image style="width: 62px; height: 62px;" @click='toTop' class="pointer"
            :src="require('@/assets/to_top.png')" fit="cover"></el-image>
    </div>
</template>

<script>
import { shopCarNumber } from "../api";
export default {
    data() {
        return {
            shopCarNumber111: 0,
            user_id: localStorage.getItem("allUserMr")?JSON.parse(localStorage.getItem("allUserMr")).user_id:0,
            name1:''
        }
    },
    created() {
        this.$bus.$off('carNum111')
        this.$bus.$on('carNum111', () => {
            this.fnCarNum()
        })
    },
    mounted() {
        if(this.user_id)(
            this.fnCarNum()
        )
        
        this.name1=this.$route.name
        
    },
    methods: {
        fnCar() {
            this.$router.push({
                name: 'car'
            })
        },
        fnCarNum() {
            let params = {}
            shopCarNumber(params).then((data) => {
                if (data.status == 200) {
                    this.shopCarNumber111 = data.msg || 0
                }
            })
        },
        toTop() {
            let intervalId = setInterval(function () {
                window.scrollBy(0, -60)
                if (window.pageYOffset == 0) {
                    clearInterval(intervalId)
                }
            }, 10)
            // document.documentElement.scrollTop = 0
        },

    }
}
</script>

<style scoped>
.car_num {
    position: absolute;
    left: 34px;
    bottom: -3px;

    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    height: 28px;
    min-width: 28px;
    box-sizing: border-box;
    padding: 0 9px;
    border-radius: 14px;
    background-color: #FF0000;
}

.right_icon111{
    position: fixed;
    /* margin: auto; */
    /* right: 50px; */
    right: calc(( 100% - 1200px ) / 2 - 107px);
    /* top: 0; */
    bottom: 120px;
}
</style>
