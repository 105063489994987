<template>
    <div class="bg-fff">
        <Header />
        <div class="flex-wrap" style="height: 60px;width: 1200px;margin: 30px auto 42px;">
      <CateList />

      <div class="flex-con align-end font-999-16 bg-fff1 p_r18">
        <div>{{ $t('logon.local') }}</div>
        <div class="pointer crumbs" @click="toMain()">{{ $t('logon.home') }}</div>
         > {{ $t('headGRZXObj.dzgl') }}
      </div>

    </div>
        <div class="flex-wrap box margin">
            <div class="left1">
                <MyInfo :num111="7" />
            </div>
            <div class="flex-con four-box">
                <!-- <div class="font-333-20-500 align-between">
                    <div>{{ $t('addressObj.dzgl') }}</div>
                </div> -->
                <div class="font-005BAC-24-500 align-center bold" style="margin-bottom:25px;">
                    <div>{{ $t('headGRZXObj.dzgl').toUpperCase() }} <span class="xian"></span> </div>
                </div>
                <div class="four-out">
                    <div class="four border_eee relative" :class="{ active: item.addr_default == 1, pointer: from }"
                        v-for="(item, index) in addressListObj" :key="'address_' + index" @click.stop="fnSelect(item)">
                        <div class="xian2"></div>
                        <div style="padding:0 10px">
                            <div class="info1 m_b12">
                            <span class="bold">{{ $t('logon.d131') }}</span>: {{ item.addr_xing + item.addr_name }}
                        </div>
                        <div class="info1 m_b30">
                            <span class="bold">{{ $t('orderObj.address') }}</span>: {{ item.addr_address || '' }}
                        </div>
                        </div>
                        
                        <!-- <div class="font-333-14">
                            <div class="m_t8">{{ item.addr_address || '' }}</div>
                            <div class="m_t8">{{ item.addr_postal || '' }}（{{ $t('addressObj.yb') }}）</div>
                            <div class="m_t8">{{ item.addr_phone || '' }}</div>
                        </div> -->
                        <div class="icon-all align-between">
                            <div class="pointer" v-if="item.addr_default == 1" @click.stop="fnDefaultAddress(item.addr_id,2)" >{{ $t('addressObj.cancelDefault') }}</div>
                            <div class="pointer" v-else @click.stop="fnDefaultAddress(item.addr_id,1)">{{ $t('addressObj.setDefault') }}</div>
                            <div class="align-center">
                                <div class="pointer edit1" @click.stop="fnAddAddress(1, item.addr_id)">
                                    {{ $t('addressObj.edit') }}</div> &nbsp;|&nbsp; <div class="pointer"
                                    @click.stop="fnDelAddress(item.addr_id)">{{ $t('carObj.sc') }}</div>
                            </div>
                            <!-- <img :src="require('@/assets/edit.png')" alt="" class="pointer"
                                @click.stop="fnAddAddress(1, item.addr_id)">
                            <img :src="require('@/assets/delete.png')" alt="" class="pointer"
                                @click.stop="fnDelAddress(item.addr_id)"> -->
                        </div>




                    </div>
                </div>
                <div class="title2 align-center pointer" @click="fnAddAddress">
                    <img :src="require('@/assets/jia1.png')" alt="" class="jia1" >
                    <div>{{ $t('addressObj.addAddress') }}</div>
                </div>

            </div>
        </div>
        <Footer />
        <Icon />
    </div>
</template>

<script>
import Icon from '@/components/Icon.vue'
import CateList from '@/components/CateList.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import { addressList, delAddress , defaultAddress } from '../api'
export default {
    components: {
        Header,
        Footer,
        MyInfo,
        CateList,
        Icon
    },
    data() {
        return {
            addressListObj: [],
            from: this.$route.query.from,
            // item: {}
        }
    },
    mounted() {
        this.initAddressList()
    },
    // destroyed() {
    //     this.$bus.$emit(
    //         'address',
    //         this.item
    //     )
    // },
    methods: {
        fnSelect(item) {
            if (this.from) {
                localStorage.setItem('wlAddressPc', JSON.stringify(item));
                this.$router.go(-1)
            }
        },
        fnDelAddress(idd) {
            let params = {
                addr_id: idd
            }
            delAddress(params).then((data) => {
                if (data.status == 200) {
                    this.$message.success(data.msg)
                    this.initAddressList()
                }
            })
        }, 

        fnDefaultAddress(idd,type) {
            let params = {
                addr_id: idd,
                addr_default:type
            }
            defaultAddress(params).then((data) => {
                if (data.status == 200) {
                    this.$message.success(data.msg)
                    this.initAddressList()
                }
            })
        },

        initAddressList() {
            let params = {}
            addressList(params).then((data) => {
                if (data.status == 200) {
                    this.addressListObj = data.msg
                }
            })
        },
        fnAddAddress(type, idd) {//type==1,idd有值为 编辑地址 否则添加地址
            if (type == 1) {
                this.$router.push({
                    path: `/newAddress/${idd}`
                });
            } else {
                this.$router.push({
                    path: 'newAddress'
                });
            }
        }
    }

}
</script>

<style scoped>
.title2 {
    width: 240px;
    height: 48px;
    background: #005BAC;
    border-radius: 24px;
    font-size: 16px;
    color: #FFFFFF;
    margin-top: 28px;
}

.jia1 {
    width: 24px;
    height: 24px;
    margin-right: 24px;
}

.four .info1{
    font-size: 16px;
    color: #666666;
}

.four.active .info1{
    color: #fff;
}

.four .icon-all {
    font-size: 16px;
    color: #005BAC;
}

.four.active .icon-all {
    color: #FFFFFF;
}

.four.active .edit1{
    color: #B5DCFF;
}

.four-box {
    /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); */
    padding: 0 86px 0 132px;
}

.four-out {
    display: flex;
    flex-wrap: wrap;
}

.four-out .four {
    margin-right: 40px;
    margin-top: 28px;
    position: relative;
    display: inline-block;
    width: calc((100% - 40px)/2);
    box-sizing: border-box;
    padding: 16px;
    padding-top: 14px;
    transition: all 0.3s;
    /* border-radius: 4px; */
    /* border: 1px solid #008DD1; */
}

.four-out .four.active {
    background: #005BAC;
}

.four-out .four:nth-child(2n) {
    margin-right: 0;
}

.btn1 {
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 25px;
    height: 34px;
    background: #008DD1;
    border-radius: 17px;
    width: 208px;
    transition: all 0.3s;
}

.four:hover .product-intro {
    display: block;
}

.four:hover .fourImg,
.four:hover .btn1 {
    display: none;
}

.product-intro {
    height: 40%;
    /* overflow-y: scroll; */
    display: none;
    margin-top: 20px;
    padding: 0 20px;
    transition: all 0.3s;
}

.product-intro::-webkit-scrollbar {
    display: none;
}

.collect {
    display: none;
    width: 28px;
    height: 28px;
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 1;
}

.left1 {
    width: 200px;
    /* margin-right: 30px; */
}

.box {
    padding: 26px 20px;
    width: 1200px;
    background-color: #FFFFFF;
    box-sizing: border-box;
}

.xian2 {
  position: absolute;
  margin: auto;
  left: 12px;
  top: 13px;
  width: 6px;
  height: 18px;
  background: #F9BE00;
}
</style>