const Portuguese = {
    JC:'PT',
    JCs:'pt',
    hello: 'Olá',
    headSY: 'Página inicial',
    headXJD: 'Folha de consulta',
    headQYCP: 'Produtos da empresa',
    headGYWM: 'Sobre nós',
    headLXWM: 'Contato',
    headYSZC: 'Política de privacidade',
    headSSCP: 'Pesquisa de produtos',
    headGRZX: 'Informações sobre a conta',
    headYY: 'Inglês',
    headXBYY: 'Español',
    headPTYY: 'Português',
    mainCPFL: 'Categorias de produtos',
    mainTJCP: 'Produtos recomendados',
    mainTJCPYH: 'Produtos mais recentes recomendados',
    mainCK: 'Exibir revisão',
    content:'',
    new: {
        prev: 'Previous1',
        next: 'Next1'
    },
    other:{
        cart:'Shopping Cart1',
        addedSuccess:'Added successfully!1',
        contBrowse:'Continue browsing1',
        toCart: 'Go to shopping cart1',
        cancel:'Cancel1',
        confirm:'Confirm1',
        success:'Copy Successful1',
        fail:'Copy Failed1',
        sure0:'Aguarde o administrador calcular o preço antes de confirmá-lo.',
        sure1:'Are you sure you want to confirm the order?1',
        sure2:'Would you like to cancel the order?1',
        sure3:'Do you confirm the receipt of the goods?1',
        confirmOrder:'Confirm order1'

    },


    home:{
        help:'Help1',
        shop:'MolChem.shop1',
        platform:'Chemical Procurement Platform1',
        safe:'Safe and transparent1',
        place:'Please Enter CAS No. or Product Name1',
        allProducts:'All Products1',
        productsTotal:'Products Total1',
        completedTotal:'Completed Orders Total1',
        recommendation:"Today's Recommendation1",
        what:'What We Do?1',
        doWhat:'Was established with the aim to build the most comprehensive database of chemical products and contain most complete suppliers, making chemical trading more efficient, convenient and safe. facilitate global chemical trading and bring chemical buyers & sellers from all over the world to one common chemical platform.1',
        why:'Why Choose Us？1',
        why1:'Complete categories and sufficient inventory;1',
        why2:'More reasonable prices;1',
        why3:'Transparent operational procedures;1',
        hot:'Popular Products1',
        cate:'Products For Categories1',
        buy:'How To Buy1',
        language:'Language1',
        eng:'English1',
        pro:'Portuguese1',
        spa:'Spanish1',
        page:'Home Page1',
        copyright:'Copyright © 2024 MolChem.shop All rights reserved.1'


    },
    footAll: {
        rmlb: 'Productos mais populares',
        fwzc: 'Política de serviço',
        sjbhzc: 'Política de proteção de dados',
        yszc: 'Política de privacidade',
        gywm: 'Sobre nós',
        gsjs: 'Introdução da Empresa',
        lxwm: 'Coentre em contato conoscontato',
        dz: 'Endereço',
        zb: 'Sede da empresa',
        js: 'Introduction1',
        news: 'News & Events1'
    },
    headGRZXObj: {
        jbxx: 'informações básicas',
        dzgl: 'gerenciamento de endereços',
        wdsc: 'Meu inventário de produtos',
        qbdd: 'Todos os pedidos',
        dqr: 'a serem confirmados',
        dfk: 'A serem pagos',
        dfh: 'A ser enviado',
        dsh: 'To be received',
        ywc: 'Recebido',
        yqx: 'Cancelado',
        tczh: 'Logout',
        zhzx: 'Centro da conta',
        ddzx: 'Central de pedidos',
    },
    jbXX: {
        xing: 'Sobrenome',
        ming: 'primeiro nome',
        dzyx: 'Endereço de e-mail', 
        gs: 'Empresa',
        zw: 'Cargo',
        dhhm: 'Número de telefone',
        tj: 'Adicionar',
        mm: 'Senha',
        ggmm: 'Alterar senha',
        ndqmm: 'Senha atual',
        ndxmm: 'nova senha',
        cxsrndxmm: 'reinserir sua senha',
        qsr: 'Por favor, digite',
        lcbyz: 'Duas novas entradas de senha são inconsistentes',

        email:'E-mail (Also Member Account)1',
        name:'Your Name1',
        country:'Country or Region1',
        corporate:'Corporate Name1',
        address:'Your Company Address1',
        contact:'Contact Information1',
        contactPlace:'Your Telphone Or Email1',
        cpwd:'Enter Current Password1',
        npwd:'Enter New Password1',
        rpwd:'Repeat Password1',
        confirm:'Confirm1'
    },
    addressObj: {
        mo: 'Definir como endereço padrão',
        xdz: 'Novo endereço',
        xxdz: 'Endereço detalhado',
        yb: 'Código postal',
        dzgl: 'Gerenciamento de endereços',
        mrdz: 'Endereço padrão',
        qr: 'Confirmação',
        bjdz: 'Editar endereço',
        addAddress: 'Add Address1',
        setDefault: 'Set Default1',
        cancelDefault: 'Cancel Default1',
        edit: 'Edit1',
        editInformation: 'Edit Information1',
        shippingAddress: 'Shipping Address1',
        shipmentPort: 'Shipment Port1',
    },
    cateObj: {
        lb: 'Categoria',
        sx: 'Atributo'
    },
    goodsObj: {
        bz: 'Embalagem',
        sl: 'Quantidade',
        xzgg: 'Escolha o grau',
        ljgm: 'Comprar agora',
        zj: 'Total',
        jrgwc: 'Adicionar ao carrinho',
        cpxq: 'Detalhes do produto',
        tjsc: 'Adicionar economia',
        qxsc: 'Cancelar economia',        
        buy:'Purchase1',
        toCart:'Add to Cart1',
        amountTotal: 'Amount Total1',
        buyNow: 'Buy Now1',
        moQ: 'MOQ1',
    },
    carObj: {
        sc: 'Excluir',
        qx: 'Selecionar tudo',
        cpxx: 'Informações sobre o produto',
        cpgg: 'Especificação do produto',
        dj: 'Preço unitário',
        sl: 'Quantidade',
        xj: 'Subtotal',
        gwczj: 'total da cesta',
        je: 'Quantidade',
        tj: 'Enviar',
        xz: 'Selecionar o produto excluído',
        qrsc: 'Confirmar a exclusão',
        qux: 'Cancelar',
        qr: 'Confirmar',
        xzcp: 'Selecionar um produto',
        tjdd: 'Enviar pedido',
        cpqd: 'Lista de produtos',
        tjdz: 'Adicionar endereço',
        qxzdz: 'Choose an address',

        tip:'tip1',
        noOtherPrice:'This price does not include shipping and other fees.1',
        calcPrice:'After placing the order, we will calculate the accurate price for you.1',
        confirmSA:'Confirm shipping address1',

        back:'Retorno'
    },
    orderObj: {
        ddxx: 'Informações do pedido',
        gg: 'Especificações',
        zt: 'Status',
        cz: 'Operação',
        ddsj: 'Data do pedido',
        ddbh: 'Número do pedido',
        ckdd: 'Exibir ordem',
        qrdd: 'Confirmar pedido',
        qxdd: 'Cancelar pedido',
        qrsh: 'Confirmar o recebimento de mercadorias',
        zlyd: 'Refazer um pedido',
        ddxq: 'Detalhes do pedido',
        qm: 'full name',
        dh: 'Telefone',
        wl: 'Logística',
        w: 'nenhum',
        hwys: 'transporte',


        allPurOrder: 'All purchase orders1',
        searchP: 'Enter Order ID. or Product Name1',
        search: 'search1',
        orderProcess: 'Order Processing Process1',
        order1:'Price Accounting1',
        order2:'Confirm Order1',
        order3:'Payment1',
        order4:'Send out goods1',
        order5:'Waiting for delivery1',
        order6:'Confirm Receipt1',
        order_intro1: 'After generating the order,the seller calculates the total price of the order.1',
        order_intro2: 'The buyer confirms the list of goods and price information.1',
        order_intro3: 'The buyer pays the purchase price.1',
        order_intro4: 'After receiving the payment, the seller arranges for shipment.1',
        order_intro5: 'The buyer is waiting for receipt of the goods.1',
        order_intro6: 'The buyer inspects the goods and confirms receipt of the goods.1',
        filter:'Order filtering: Presenter1',
        reorder:'Reorder1',
        goodsPrice:'Price of goods1',
        otherExpenses:'Other Expenses1',
        waitAccount:'Waiting for accounting1',
        totalAmount:'Total Amount1',
        presenter:'Presenter1',
        orderID:'Order ID1',
        orderStatus:'Order Status1',
        wlIntro1:'Goods have been shipped, logistics information:1',
        wlIntro2:'We have received the payment and will ship soo.1',
        wlIntro3:'Your formal order has been generated, please confirm and pay the payment as soon as possible.1',
        shipAddress:'shipping address1',
        emailOnly:'E-mail1',
        address:'Add1',
        tariff:'Tariff1',
        tranCosts:'Transportation costs1',
        sRequire:'special requirements1',
        sRequireXq:'If there are special requirements, please fill in here.1',
    },
    logon: {
        dl: 'Iniciar sessão',
        dl1: '',
        dl2: 'Fazer um pedido rapidamente',
        dl3: 'Directly connect with the most favorable price',
        dl4: 'Efficient response',
        dl5: 'digite seu endereço de e-mail',
        dl6: 'digite sua senha',
        dl7: 'Esquecer a senha',
        dl8: 'Não tem conta? Registar-se como',
        dl9: 'digite o código de verificação',
        dl10: 'digite novamente a senha',
        dl11: 'envie o código de verificação',
        dl12: 'A verificação falhou',
        dl13: 'Duas senhas inseridas não correspondem',
        dl14: 'Enviar com sucesso',
        dl15: 'Recuperar',
        dl16: 'dados completos',
        dl17: 'digite seu sobrenome',
        dl18: 'Digite seu primeiro nome',
        dl19: 'Digite seu endereço de e-mail',
        dl20: 'Digite seu número de telefone',
        dl21: 'informe o nome da empresa',
        dl22: 'digite seu cargo',
        dl23: 'Em verificação',
        dl24: 'Enviar novamente',
        dl25: 'Enviar',
        dl26: 'Lembrar',
        dl27: 'falha de verificação',
        dl28: 'Na verificação, aguarde pacientemente',
        d129:'Usuário',
        d130:'Senha',
        d131:'primeiro nome',
        d132:'endereço de e-mail',
        d133:'Telefone',
        d134:'Empresa',
        d135:'Posição',
        d136:'Comprador',

        already:'If you are already a member of molchem.shop please login below:',
        email:'E-mail Address:',
        emailP:'Enter Your E-mail',
        enterPassword:'Enter Password',
        forgotten:'Forgotten Password?',
        account:"Don't have an account yet?",
        resetPwd:'reset password',
        resetPwdTxt:'Obtain verification code through email to reset password.',
        verCode:'Verification Code:',
        enterCode:'Enter Verification Code',
        code:'Get Code',
        local:'Local：',
        home:'Home1',
        resetPwd1:'Reset password',
        registration:'Registration',
        registerTxt1:'Here you can register free of charge for my.chemeurope.com. With the registration you will receive your personal access to many useful features.',
        registerTxt2:'The e-mail address you enter will also be your user name.',
        registerTxt3:'By creating an account, you agree to MOLBASE’s Conditions of Use and ',
        notice:'Privacy Notice',
        register:'Register',
        anAccount:'Already have an account?',
        sign:'Sign in>',
        regSuccess:'Registered successfully!',
        completeInfo:'Please go to your member center to complete member information.',
        ok:'OK',
    },
    xjd: {
        txxjd: 'Preencher o formulário de inscrição',
        xjdlb: 'Lista de formulários de inscrição',
        xjcp: 'Produtos solicitados',
        pthf: 'Plataforma de resposta',
        lxr: 'Contato',
        lxrxx: 'número de telefone do contato',
        srlxr: 'Inserir contato',
        srlxrxx: 'digite o número de telefone do contato',
        cpsl: 'Digite o nome e a quantidade do produto solicitado',
        myg:'data de chegada ao porto',
        cyg:'Porto de embarque',
        mdg:'Porto de destino',
        ysfs:'Condições de pagamento',
        mysysj:'Termos e condições de negócios',
        qxz:'Selecionar',
        xzbz:'Selecionar embalagem',
        txsl:'Preencher a quantidade',
        xzhb:'Selecione a moeda',
        cgoods:'selecionar o produto',
        scdd:'Gerar pedido',
        addd:'Adicionar',
        tishi:'Lembrar de mim',
        qx:'Cancelar',
        cg:'Determinar',
        tsxx:'Essa operação excluirá permanentemente o registro de modificação, Deseja continuar?',
        sccg:'exclusão bem-sucedida',
        qxsc:'Cancelar exclusão',
        ss:'pesquisar',
        xjdbh:'Consultar número da folha',
        cggsmc:'Nome da empresa',
        cggsdz:'Endereço da empresa',
        cgyxm:'Nome do comprador',
        cgydh:'Número de telefone do comprador',
        yjdz:'Endereço de e-mail',
        dj:'Preço unitário',
        zongjia:'Total',
        dw:'Unidade',
        txdw:'Preencher a unidade',
        cp:'produto',
    }
}
export default Portuguese