<template>
  <div>

    <Header />

    <div class="box">

      <div class="flex-wrap" style="height: 60px;width: 1200px;margin: 0 auto 50px;">
        <CateList />

        <div class="flex-con align-end font-999-16 bg-fff1 p_r18">
          <div>{{ $t('logon.local') }}</div>
          <div class="pointer crumbs" @click="toMain()">{{ $t('logon.home') }}</div>
           > {{ $t('logon.register') }}
        </div>

      </div>



      <div class="right_box">
        <div class="font-005BAC-24-500 align-center bold" style="margin-bottom:30px;">
          <div>{{ $t('logon.register').toUpperCase() }} <span class="xian"></span> </div>
        </div>

        <div style="margin-bottom: 2px;" class="font-666-14">{{ $t('logon.registerTxt1') }} <br>{{
          $t('logon.registerTxt2') }}</div>
        <div style="margin: 34px 0 20px;" class="font-005BAC-16">{{ $t('logon.email') }}</div>
        <div class="flex-wrap">
          <input type="text" v-model="email" class="flex-con" :placeholder="$t('logon.emailP')" value="">

          <div class="getCode align-center pointer" @click="need_ma()">{{ yzm }}</div>

        </div>
        <div style="margin: 20px 0 20px;" class="font-005BAC-16">{{ $t('logon.verCode') }}</div>
        <input type="text" v-model="sms" class="flex-con" :placeholder="$t('logon.enterCode')" value="">

        <div style="margin: 20px 0 20px;" class="font-005BAC-16">{{ $t('jbXX.mm') }}:</div>
        <input type="password" v-model="pwd" class="flex-con" :placeholder="$t('logon.enterPassword')" value="">

        <div style="margin: 20px 0 20px;" class="font-005BAC-16">{{ $t('jbXX.rpwd') }}:</div>
        <input type="password" v-model="pwd1" class="flex-con" :placeholder="$t('jbXX.rpwd')" value="">
        <div class="font-666-14 m_t30">
          {{ $t('logon.registerTxt3') }} <span class="color-005BAC pointer" @click="toPrivacy">{{ $t('logon.notice') }}</span>
        </div>
        <div class="btn pointer align-center" @click="fnOver">{{ $t('logon.register') }} </div>
        <div class="font-666-16">
          {{ $t('logon.anAccount') }} <span class="color-005BAC pointer" @click="toLogin">{{ $t('logon.sign') }}</span>
        </div>
      </div>


    </div>

    <Footer />

    <el-dialog :title="$t('logon.regSuccess')" :visible.sync="dialogVisible" :show-close="false" append-to-body center
      :close-on-click-modal="false" :close-on-press-escape="false" style="margin-top:35vh;">
      <span>{{ $t('logon.completeInfo') }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="fnSure" size="mini">{{ $t('logon.ok') }}</el-button>
      </span>
    </el-dialog>


  </div>

</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import CateList from '@/components/CateList.vue'
import { sendCode, emailRegister } from '../api'
export default {
  components: {
    Header,
    Footer,
    CateList
  },
  data() {
    return {
      email: '',
      sms: '',
      yzm: this.$t('logon.code'),
      isOnclick: true,
      onClickTime: '',
      isinerval: '',
      pwd: '',
      pwd1: '',
      dialogVisible: false,
      user:{}
    }
  },
  methods: {
    fnOver() {
      if (this.email == '') {
        this.$message.error(this.$t('logon.dl5'))
        return;
      }
      if (this.sms == '') {
        this.$message.error(this.$t('logon.dl9'));
        return;
      }
      if (!/^\d{6}$/.test(this.sms)) {
        this.$message.error(this.$t('logon.dl12'));
        return;
      }
      if (!this.pwd) {
        this.$message.error(this.$t('logon.dl6'));
        return;
      }
      if (this.pwd != this.pwd1) {
        this.$message.error(this.$t('logon.dl13'));
        return;
      }
      emailRegister({
        email: this.email,
        code: this.sms,
        pwd: this.pwd,
        verifyPwd: this.pwd1,
      }).then((data) => {
        if (data.status == 200) {
          this.dialogVisible = true
          localStorage.setItem('allUserMr', JSON.stringify(data.user))
        }
      })

    },
    fnSure() {
      this.$router.push({
        name: 'myInfo',
      })
    },
    
    toLogin() {
      this.$router.push({ name: 'login' })
    },
    toPrivacy() {
      this.$router.push({ name: 'pPolicy' })
    },
    need_ma() {
      if (this.email == '') {
        this.$message.error(this.$t('logon.dl5'))
        return;
      }
      if (this.isOnclick) {
        //调用接口判断
        sendCode({
          email: this.email,
          type: 1
        }).then((data) => {
          if (data.status == 200) {
            this.$message.success(this.$t('logon.dl14'))
            if (this.isOnclick) {
              this.onClickTime = Math.floor((new Date().getTime()) / 1000)
              this.isinerval = setInterval(this.CountDown, 1000);
              this.isOnclick = false;
            }
          }
        })
      }
    },
    CountDown() {
      this.onClickTime1 = 60 - (Math.floor((new Date().getTime()) / 1000) - this.onClickTime);
      if (this.onClickTime1 < 1) {
        this.yzm = this.$t('logon.code');
        clearInterval(this.isinerval);
        this.isOnclick = true;
        return;
      }
      this.yzm = this.onClickTime1 + 's';
    },
  },
  mounted() {
  }

}
</script>


<style scoped>
.head_contain {
  width: 100%;
  min-height: 110px;
  font-size: 16px;
  line-height: 22px;
  color: #008DD1;
}

.head_top {
  background-color: #fff;
  height: 90px;
  padding: 0 60px;
}

.head_top>div,
.head_top>img {
  margin-left: 38px;
}

.logo {
  width: 269px;
  height: 56px;
  margin-left: 60px;
  margin-top: 70px;
}

.head_tab {
  height: 42px;
  background: #008DD1;
  padding: 0 60px;
  color: #fff;
  font-weight: normal;
}

.box {
  /* 
  background: url('@/assets/home/bg_login.png') no-repeat center center;
  background-size: 100% 100%; */
  padding-top: 30px;
  min-height: 938px;
  background: #F3F3F3;
}

.title {
  font-size: 22px;
  font-weight: 400;
  color: #333333;
  line-height: 15px;
  text-align: left;
  margin-bottom: 30px;
}

.info {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  line-height: 21px;
  text-align: center;
  margin-bottom: 50px;
}

.tu1 {
  display: block;
  width: 75%;
  height: 114px;
  margin: 22px auto 0;
}


.right {
  flex: 1;
}

.one {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #DDDDDD;
  margin-bottom: 25px;
}

.kuang {
  width: 65px;
  border-right: 1px solid #DDDDDD;
}

.icon {
  display: block;
  width: 36px;
  height: 36px;
  margin: 6px auto;
}

input[type='text'],
input[type='password'],
input[type='tel'] {
  width: 100%;
  background: rgba(0, 0, 0, 0);
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  height: 43px;
  padding: 0 16px;
  color: #333;
  font-size: 16px;
  outline: none;
}

input[type='text']::-webkit-input-placeholder,
input[type='password']::-webkit-input-placeholder,
input[type='tel']::-webkit-input-placeholder {
  color: #ccc;
}

.getCode {
  width: 101px;
  height: 42px;
  background: #005BAC;
  font-size: 16px;
  color: #FFFFFF;
  margin-left: 26px;
}

.button {
  padding: 0 24px;
}

.btn {
  width: 240px;
  height: 48px;
  background: #005BAC;
  border-radius: 24px;
  font-size: 18px;
  color: #FFFFFF;
  margin: 34px auto 32px;
}

.icon1 {
  display: block;
  width: 25px;
  height: 25px;
  margin-right: 16px;
}



.leftTxt {
  font-size: 15px;
  font-weight: 400;
  color: #333333;
  width: 75%;
  margin: auto;
}

.oneTxt {
  align-items: center;
}

.bottom {
  font-size: 15px;
  font-weight: 400;
  color: #666666;
  margin-top: 30px;
}

.left {
  width: 1400px;
  height: 100vh;
  background-image: url('@/assets/loginbg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.el-dropdown {
  font-size: 18px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #999;
}

.el-icon-arrow-down {
  font-size: 18px;
}

.right_box {
  width: 661px;
  /* height: 568px; */
  /* background: #FFFFFF; */
  padding: 20px 52px 0 50px;
  box-sizing: border-box;
  margin: 0 auto;

}

::v-deep .el-dialog {
  position: relative;
  margin: 0 auto 50px;
  background: rgba(0, 91, 172, .9);
  border-radius: 25px;
  box-sizing: border-box;
  width: 504px;
  height: 247px;
}

::v-deep .el-dialog__header {
  padding: 37px 20px 32px;
}

::v-deep .el-dialog__title {
  line-height: 24px;
  font-size: 24px;
  color: #FFFFFF;
}

::v-deep .el-dialog__body {
  padding: 30px 20px;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 27px;
}

::v-deep .el-dialog--center .el-dialog__body {
  padding: 0 70px;
}

::v-deep .el-button--mini {
  width: 240px;
  height: 48px;
  background: #F9BE00;
  border-radius: 24px;
  font-size: 18px;
  color: #005BAC;
}

::v-deep .el-button--primary {
  border: none;
}

::v-deep .el-button--primary:hover .el-button--mini {
  background: #F9BE00;
}

::v-deep .el-button--primary:focus,
::v-deep .el-button--primary:hover {
  background: #F9BE00;
  border: none;
  color: #005BAC
}

::v-deep .el-dialog__footer {
  padding: 30px 20px 0;
}
</style>