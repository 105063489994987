<template>
  <div>
    <Header />
    <div class="flex-wrap" style="height: 60px;width: 1200px;margin: 30px auto 42px;">
      <CateList />

      <div class="flex-con align-end font-999-16 bg-fff1 p_r18">
        <div>{{ $t('logon.local') }}</div>
        <div class="pointer crumbs" @click="toMain()">{{ $t('logon.home') }}</div>
         > {{
          type == 0 ? $t('orderObj.allPurOrder') : type == 1 ? $t('headGRZXObj.dqr') : type == 2 ? $t('headGRZXObj.dfk') : type == 3 ? $t('headGRZXObj.dfh') : type == 4 ? $t('headGRZXObj.dsh') : $t('headGRZXObj.ywc')
        }}
      </div>

    </div>
    <div class="flex-wrap box margin">
      <div class="left1">
        <MyInfo @update="initDataShua" :num="type" />
      </div>

      <div class="flex-con four-box">
        <div class="font-005BAC-24-500 align-center bold" style="margin-bottom:25px;">
          <div>{{
            (type == 0 ? $t('orderObj.allPurOrder') : type == 1 ? $t('headGRZXObj.dqr') : type == 2 ? $t('headGRZXObj.dfk') : type == 3 ? $t('headGRZXObj.dfh') : type == 4 ? $t('headGRZXObj.dsh') : $t('headGRZXObj.ywc')).toUpperCase()
            }} <span class="xian"></span> </div>
        </div>

        <div class="align-start" style="border: 2px solid #CCCCCC; width: 624px;margin: 0 auto 40px;">
          <div class="align-start">
            <img style="width: 24px; height: 24px; margin: 0 12px" :src="require('@/assets/search1.png')" fit="cover"
              alt="" />
            <input type="text" name="" v-model="keyword" value="" :placeholder="$t('orderObj.searchP')"
              @keyup.enter="fnSearch()" />
          </div>
          <div class="search_txt align-center pointer font-fff-18-bold" @click="fnSearch()">
            {{ $t('orderObj.search') }}
          </div>
        </div>

        <div class="out1 align-center3">
          <div class="align-start">
            <div class="font-005BAC-16-bold">{{ $t('orderObj.orderID') }}. </div> &nbsp;
            <div class="font-666-24-bold" id="orderNum">{{ data.order_number }}</div>
            <img style="width: 24px; height: 24px; margin: 0 20px" :src="require('@/assets/copy.png')" fit="cover"
              alt="" class="pointer" @click="copy(data.order_number)" />
          </div>
          <div class="align-start">
            <div class="font-005BAC-16-bold">{{ $t('orderObj.ddsj') }}: </div> &nbsp;
            <div class="font-666-16">{{ data.order_time }}</div>
          </div>
        </div>

        <div class="order_pro center" v-show="data.order_status < 6">
          <div class="pro_title">{{ $t('orderObj.orderStatus') }}</div>

          <div class="pro_all align-around1" style="padding:0 20px;">
            <div class="pro_item relative">
              <div class="pro_num bg_dcdcdc color-333" :class="{ pro_item_act: data.order_status > 0 }">1</div>
              <div class="pro_title1 bg_dcdcdc color-333" :class="{ pro_item_act: data.order_status > 0 }">{{
                $t('headGRZXObj.dqr') }}</div>
            </div>

            <div class="pro_item relative">
              <div class="pro_num bg_dcdcdc color-333" :class="{ pro_item_act: data.order_status > 1 }">2</div>
              <div class="pro_title1 bg_dcdcdc color-333" :class="{ pro_item_act: data.order_status > 1 }">{{
                $t('headGRZXObj.dfk') }}</div>
            </div>

            <div class="pro_item relative">
              <div class="pro_num bg_dcdcdc color-333" :class="{ pro_item_act: data.order_status > 2 }">3</div>
              <div class="pro_title1 bg_dcdcdc color-333" :class="{ pro_item_act: data.order_status > 2 }">{{
                $t('headGRZXObj.dfh') }}</div>
            </div>

            <div class="pro_item relative">
              <div class="pro_num bg_dcdcdc color-333" :class="{ pro_item_act: data.order_status > 3 }">4</div>
              <div class="pro_title1 bg_dcdcdc color-333" :class="{ pro_item_act: data.order_status > 3 }">{{
                $t('headGRZXObj.dsh') }}</div>
            </div>

            <div class="pro_item relative">
              <div class="pro_num bg_dcdcdc color-333" :class="{ pro_item_act: data.order_status > 4 }">5</div>
              <div class="pro_title1 bg_dcdcdc color-333" :class="{ pro_item_act: data.order_status > 4 }">{{
                $t('headGRZXObj.ywc') }}</div>
            </div>
          </div>
        </div>

        <div class="order11" v-show="data.order_wuLiu_desc">
          <div class="font-333-14" style="margin-left: 30px;" v-show="data.order_wuLiu_company">{{
            data.order_wuLiu_company||'' }}：<span class="color-FF0000">{{ data.order_wuLiu_code || '' }}</span></div>
          <div class="align-start m_b10">
            <img style="width: 24px; height: 24px; margin-right: 6px" :src="require('@/assets/news1.png')" fit="cover"
              alt="" />
            <div class="font-999-14">{{ data.order_update_time || '' }}</div> &nbsp;
            <div class="font-333-14">{{ data.order_wuLiu_desc || '' }} </div>
          </div>
          <!-- <div class="align-start m_b10">
            <img style="width: 24px; height: 24px; margin-right: 6px" :src="require('@/assets/news1.png')" fit="cover"
              alt="" />
            <div class="font-999-14">2024-06-15 19:25</div> &nbsp;
            <div class="font-333-14">{{ $t('orderObj.wlIntro2') }}</div>
          </div>
          <div class="align-start m_b10">
            <img style="width: 24px; height: 24px; margin-right: 6px" :src="require('@/assets/news1.png')" fit="cover"
              alt="" />
            <div class="font-999-14">2024-06-15 19:25</div> &nbsp;
            <div class="font-333-14">{{ $t('orderObj.wlIntro3') }}</div>
          </div> -->
        </div>


        <div class="font-005BAC-18-bold od_title2">{{ $t('orderObj.shipAddress').toUpperCase() }} <span
            class="xian"></span> </div>

        <div class="align-top font-666-16 relative border_eee" style="padding: 10px 28px;line-height: 26px;">
          <div class="xian2"></div>
          <div style="width:50%;">
            <div class="align-start">
              <div class="bold">{{ $t('logon.d131') }}:</div> &nbsp;
              <div>{{ data.addr_name || '' }}</div>
            </div>
            <div class="align-start">
              <div class="bold">{{ $t('orderObj.address') }}:</div> &nbsp;
              <div>{{ data.addr_address || '' }}</div>
            </div>
            <div class="align-start">
              <div class="bold">{{ $t('xjd.mysysj') }}:</div> &nbsp;
              <div>{{ data.addr_mysysj || '' }}</div>
            </div>
            <div class="align-start">
              <div class="bold">{{ $t('xjd.ysfs') }}:</div> &nbsp;
              <div>{{ data.addr_paytype || '' }}</div>
            </div>
          </div>
          <div>
            <div class="align-start">
              <div class="bold">{{ $t('xjd.mdg') }}:</div> &nbsp;
              <div>{{ data.addr_cyg || '' }}</div>
            </div>
            <div class="align-start">
              <div class="bold">{{ $t('orderObj.emailOnly') }}:</div> &nbsp;
              <div>{{ data.addr_postal || '' }}</div>
            </div>
          </div>
        </div>

        <div class="flex-wrap center box2">
          <div style="width:332px">{{ $t('orderObj.ddxx') }}</div>
          <div style="width:150px;" class="left">{{ $t('orderObj.gg') }}</div>
          <div style="width:170px;">{{ $t('goodsObj.sl') }}</div>
          <div class="flex-con">{{ $t('carObj.je') }}</div>
        </div>


        <div class="goods-item flex-wrap align-start font-333-18" v-for="(item, index) in data.orderGoods" :key="index">

          <div class="align-start border-box" style="width:332px;padding-left: 28px;">
            <img class="goods_img" :src="item.goods_thumb" alt="">
            <div class="font-333-14 m_r8" style="width:38%;">{{ $i18n.locale == 'en'
              ?
              item.goods_name_en
              : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}</div>
          </div>

          <div class="font-333-16 align-start" style="width:150px;">{{ item.packaging_name }} <br> {{ item.spec_value }}
          </div>
          <div class="font-003275-24 center" style="width:170px;">{{ item.of_quantity }}</div>
          <div class="font-333-16 flex-con center">{{ $t('JC') }} $ {{ item.of_total_price }}</div>
        </div>


        <div class="lh-60 align-end font-9c9c9c-24 p_r15" style="margin-bottom:3px;">
          {{ $t('orderObj.goodsPrice') }}:&nbsp;<span class="font-66ccff-24"> {{ $t('JC') }} ${{
            data.order_total_price - data.order_express_price
            }}</span>
        </div>



        <div class="font-666-14 relative border_eee" style="padding: 16px 28px;line-height: 26px;">
          <div class="xian2" style="top: 16px;"></div>
          <div class="font-005BAC-18-bold m_b20" style="line-height: 20px;">{{ $t('orderObj.otherExpenses') }} </div>
          <div class="align-start">
            <div>{{ $t('orderObj.tranCosts') }}:</div> &nbsp;
            <div>{{ $t('JC') }} ${{ data.order_express_price }}</div>
          </div>
          <!-- <div class="align-start">
            <div>{{ $t('orderObj.tariff') }}:</div> &nbsp;
            <div>{{ $t('JC') }} $20</div>
          </div> -->
        </div>

        <div class="lh-60 align-end font-9c9c9c-24 p_r15" style="padding-bottom:10px;border-bottom: 1px dotted #ccc;">
          {{ $t('carObj.xj') }}:&nbsp;<span class="font-66ccff-24"> {{ $t('JC') }} ${{ data.order_express_price }}</span>
        </div>

        <div class="font-F9BE00-24-bold align-end p_r15" style="margin-top: 30px;">
          <div>{{ $t('orderObj.totalAmount') }}:</div>
          &nbsp;<div class="font-005BAC-24-bold">{{ $t('JCs') }}</div>
          &nbsp;<div class="font-005BAC-36-bold relative" style="bottom:5px;">${{ data.order_total_price }}</div>
        </div>

        <div class="font-005BAC-18-bold od_title2" style="margin: 18px 0;">{{ $t('orderObj.sRequire').toUpperCase() }}
          <span class="xian"></span>
        </div>

        <textarea class="flex-con" :placeholder="$t('orderObj.sRequireXq')" readonly
          v-model="data.order_remark"></textarea>


        <div class="align-center btnOut">
          <div class="btn align-center pointer bg-005BAC" v-show="data.order_status == 1"
            @click.stop="fnSure(1, data.order_id)">
            {{
              $t('orderObj.qrdd') }}</div>
          <div class="btn align-center pointer bg-989898" v-show="data.order_status == 1 || data.order_status == 2"
            @click.stop="fnSure(2, data.order_id)">{{ $t('orderObj.qxdd')
            }}</div>
          <div class="btn align-center pointer bg-005BAC" v-show="data.order_status == 4"
            @click.stop="fnSure(3, data.order_id)">
            {{
              $t('orderObj.qrsh') }}</div>
          <div class="btn align-center pointer bg-005BAC" v-show="data.order_status == 5 && data.orderGoods.length == 1"
            @click.stop="fnGoodsDetails(data.orderGoods[0].of_goods_id)">{{ $t('orderObj.reorder') }}</div>
        </div>

        <!-- <div style="width:100% ;background-color: #ffffff;display: flex;justify-content: center;">
          <div class="box">
            <div class="font-333-20-500 m_b6">{{ $t('orderObj.ddxq') }}</div>
            <div class="font-333-18">{{ $t('orderObj.ddbh') }}#{{ data.order_number }}</div>
            <div class="font-333-18 m_t6">{{ $t('orderObj.ddsj') }} {{ data.order_time }}</div>
            <div style="padding:30px 0 30px 0 ;">
              <el-steps :active="data.order_status - 1" align-center v-show="data.order_status < 6">
                <el-step :title="$t('headGRZXObj.dqr')" icon="el-icon-edit"></el-step>
                <el-step :title="$t('headGRZXObj.dfk')"
                  :icon="data.order_status > 1 ? 'el-icon-edit' : 'el-icon-picture'"></el-step>
                <el-step :title="$t('headGRZXObj.dfh')"
                  :icon="data.order_status > 2 ? 'el-icon-edit' : 'el-icon-picture'"></el-step>
                <el-step :title="$t('headGRZXObj.dsh')"
                  :icon="data.order_status > 3 ? 'el-icon-edit' : 'el-icon-picture'"></el-step>
                <el-step :title="$t('headGRZXObj.ywc')"
                  :icon="data.order_status > 4 ? 'el-icon-edit' : 'el-icon-picture'"></el-step>
              </el-steps>
            </div>
            <div class="flex-con">
              <div class="box2">
                <div class="title2  align-start" style="border-bottom: 1px solid #E1E1E1;">
                  {{ $t('carObj.cpqd') }}
                </div>
                <div class="box3">
                  <div class="goods-item flex-wrap align-start font-333-18" v-for="(item, index) in data.orderGoods"
                    :key="index">
                    <img :src="item.goods_thumb" alt="">
                    <div class="font-333-18-500 border-box p_r20" style="width:38%;">{{ $i18n.locale == 'en'
                      ?
                      item.goods_name_en
                      : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}</div>
                    <div style="width:16%;">{{ item.packaging_name }} <br> {{ item.spec_value }}</div>
                    <div style="width:10%;">X {{ item.of_quantity }}</div>
                    <div class="right flex-con">$ {{ item.of_price }}</div>
                  </div>
                </div>
                <div class="title2  align-end" style="border-top: 1px solid #E1E1E1;height: 68px;">
                  <div style="margin-right: 30px;font-size: 18px;color: #666666;">{{ $t('goodsObj.zj') }}</div>
                  <div class="font-333-20" style="font-size: 20px;">$ {{ data.order_total_price }}</div>
                </div>
              </div>

            </div>
            <div>
              <div class="m_r30 box1">

                <div class="info-all">
                  <div class="title1 ">
                    {{ $t('orderObj.hwys') }}
                  </div>
                  <div class="font-333-18-500 m_b14">{{ $t('footAll.dz') }}</div>
                  <div class="flex-wrap m_b14" v-if="data.order_xjd_status == 3">
                    <div class="full-name">
                      <div class="font-666-14 m_b10">{{ $t('orderObj.qm') }}</div>
                      <div class="font-018ECF-18-500"> {{ data.order_xjd_gs_name || '' }}
                      </div>
                    </div>
                    <div class="flex-con">
                      <div class="font-666-14 m_b10">{{ $t('orderObj.dh') }}</div>
                      <div class="font-333-14-500">{{ data.order_xjd_user_phone || '' }}</div>
                    </div>
                  </div>
                  <div class="flex-wrap m_b14" v-else>
                    <div class="full-name">
                      <div class="font-666-14 m_b10">{{ $t('orderObj.qm') }}</div>
                      <div class="font-018ECF-18-500"> {{ data.addr_xing || '' }}{{ data.addr_name || '' }}
                      </div>
                    </div>
                    <div class="flex-con">
                      <div class="font-666-14 m_b10">{{ $t('orderObj.dh') }}</div>
                      <div class="font-333-14-500">{{ data.addr_phone || '' }}</div>
                    </div>
                  </div>

                  <div class="flex-wrap m_b14" v-if="data.order_xjd_status == 3">
                    <div class="full-name">
                      <div class="font-333-18-500 m_b14" style="color: #333333;">{{ $t('addressObj.xxdz') }}</div>
                      <div class="font-333-500" style="font-size: 14px;">{{ data.order_xjd_gs_address || '' }}</div>
                    </div>
                  </div>
                  <div class="flex-wrap m_b14" v-else>
                    <div class="full-name">
                      <div class="font-333-18-500 m_b14" style="color: #333333;">{{ $t('addressObj.xxdz') }}</div>
                      <div class="font-333-500" style="font-size: 14px;">{{ data.addr_address || '' }}</div>
                    </div>
                  </div>
                  <div class="font-333-18-500 m_b10" v-show="data.order_status == 4 || data.order_status == 5">{{
                    $t('orderObj.wl') }}</div>
                  <div class="font-333" style="line-height: 20px;color: #333333;white-space: pre-line"
                    v-show="data.order_status == 4 || data.order_status == 5"> {{ data.order_wuLiu_company || '' }}
                    {{ data.order_wuLiu_code || ''
                    }} <br>


                    {{ data.order_wuLiu_desc || '' }} </div>

                </div>

              </div>
              <div class="align-end">
                <div class="btn align-center pointer" v-show="data.order_status == 1"
                  @click.stop="fnSure(1, data.order_id)">{{
                    $t('orderObj.qrdd') }}</div>
                <div class="btn align-center pointer" v-show="data.order_status == 1 || data.order_status == 2"
                  @click.stop="fnSure(2, data.order_id)">{{ $t('orderObj.qxdd')
                  }}</div>
                <div class="btn align-center pointer" v-show="data.order_status == 4"
                  @click.stop="fnSure(3, data.order_id)">{{
                    $t('orderObj.qrsh') }}</div>
                <div class="btn align-center pointer" v-show="data.order_status == 5 && data.orderGoods.length == 1"
                  @click.stop="fnGoodsDetails(data.orderGoods[0].of_goods_id)">{{ $t('orderObj.zlyd') }}</div>
              </div>

            </div>
          </div>
        </div> -->
      </div>
    </div>
    <Footer />
    <Icon />
    <el-dialog :title="$t('carObj.tip')" :visible.sync="dialogVisible" width="30%" :before-close="handleClose"
      append-to-body>
      <span>{{ ts }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">{{ $t('other.cancel') }}</el-button>
        <el-button type="primary" @click="submit" size="mini">{{ $t('other.confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<!-- <div class="flex-wrap m_b14">
                            <div class="full-name">
                                <div class="font-666-16 m_b10">province</div>
                                <div class="font-333-18-500">Ciudad，STATE 0564673</div>
                            </div>
                            <div class="flex-con">
                                <div class="font-666-16 m_b10">country</div>
                                <div class="font-333-18-500">China</div>
                            </div>
                            <div class="flex-con">
                                <div class="font-666-16 m_b10">{{ $t('orderObj.dh') }}</div>
                                <div class="font-333-18-500">{{ data.addr_phone || '' }}</div>
                            </div>
                        </div> -->
<script>
import Icon from '@/components/Icon.vue'
import CateList from '@/components/CateList.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import { orderDetail, sureOrder, overOrder, cancelOrder } from '../api'
export default {
  components: {
    Header,
    Footer,
    MyInfo,
    CateList,
    Icon
  },
  data() {
    return {
      data: {},
      ts: '',
      keyword: '',
      dialogVisible: false,
      orderId: '',
      where: 0,

      type: 0,
      allGoodsPrice: 0
    }
  },
  mounted() {
    this.initOrderDetail()

    let can = this.$route.params.id
    this.type = can
  },
  methods: {
    initDataShua(idd) {
      // console.log(idd);
      this.type = idd
      // this.page = 1
      // this.initOrderList()

    },
    initOrderDetail() {
      let params = {
        order_id: this.$route.params.order_id
      }
      orderDetail(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg


          // data.msg.orderGoods.forEach((element) => {
          //   this.allGoodsPrice = (Number(element.of_total_price) + Number(this.allGoodsPrice)).toFixed(2)


          // })




        }
      })
    },
    fnSure(type, id) {
      this.dialogVisible = true;
      this.where = type;
      if (this.where == 1) {
        this.ts = this.$t('other.sure1')
      } else if (this.where == 2) {
        this.ts = this.$t('other.sure2')
      } else {
        this.ts = this.$t('other.sure3')
      }
      this.orderId = id;
    },
    submit() {
      if (this.where == 1) {
        this.fnSureOrder(this.orderId);
      } else if (this.where == 2) {
        this.fnCancleOrder(this.orderId);
      } else {
        this.fnSureSh(this.orderId)
      }
    },
    fnSureOrder(idd) {
      let params = {
        order_id: idd
      }
      sureOrder(params).then((data) => {
        if (data.status == 200) {
          this.$message.success(data.msg)
          this.dialogVisible = false;
          this.initOrderDetail()
        }
      })
    },
    fnCancleOrder(idd) {
      let params = {
        order_id: idd
      }
      cancelOrder(params).then((data) => {
        if (data.status == 200) {
          this.$message.success(data.msg)
          this.dialogVisible = false;
          this.initOrderDetail()
        }
      })
    },
    fnSureSh(idd) {
      let params = {
        order_id: idd
      }
      overOrder(params).then((data) => {
        if (data.status == 200) {
          this.$message.success(data.msg)
          this.dialogVisible = false;
          this.initOrderDetail()
        }
      })
    },

    fnSearch() {
      localStorage.setItem('orderKeyword', this.keyword)
      this.$router.push({
        name: 'orderCenter',
        params: {
          id: 1,
          keyword: this.keyword
        }
      })
    },

    copy(num) {
      navigator.clipboard.writeText(num)
        .then(() => alert(this.$t('other.success') + '!')).catch((err) => alert(this.$t('other.fail') + '!'));
    },
    handleClose(done) {
      done();
    },
  }

}
</script>

<style scoped>
.btn {
  min-width: 240px;
  height: 48px;
  border-radius: 24px;
  margin-top: 37px;
  font-size: 18px;
  color: #ffffff;
  /* line-height: 48px; */
  margin-left: 57px;
}

.btnOut>.btn:first-child {
  margin-left: 0;
}

.goods-item {
  margin-top: 15px;
  border: 1px solid #EEEEEE;
  padding: 24px 0 26px 0;
}

.goods-item:hover {
  background: #F8F8F8;
}

.goods-item img.goods_img {
  width: 78px;
  height: 70px;
  min-width: 78px;
  margin-right: 12px;
}

.box3 {
  padding: 4px 42px 16px;
}

.title2 {
  font-size: 22px;
  font-weight: 500;
  color: #333333;
  line-height: 30px;
  height: 50px;
  padding: 0 42px;
  box-sizing: border-box;

}

.full-name {
  min-width: 38%;
  padding-right: 20px;
  box-sizing: border-box;
}

.info-all {
  padding: 16px 42px;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #E1E1E1;
}

.title1 {
  font-size: 18px;
  font-weight: 500;
  color: #008dd1;
  line-height: 30px;



  height: 50px;

}

.box1 {
  width: 1200px;
}

.box {
  padding: 26px 20px 50px;
  width: 1200px;
  box-sizing: border-box;
  background-color: #fff;
}

::v-deep .el-step.is-center .el-step__line {
  left: 70%;
  right: -30%;
}

::v-deep .el-step.is-horizontal .el-step__line {
  height: 4px;
  top: 18px;
  background-color: #008dd1;
}

::v-deep .el-step__head.is-finish .el-step__line {
  height: 4px;
  background-color: #f9be00;
}

::v-deep .el-step__head.is-finish .el-step__line .el-step__line-inner {
  border-width: 0 !important;
}

::v-deep .el-step__icon.is-icon {
  width: 40px;
  height: 40px;
}

::v-deep .el-icon-edit:before {
  display: block;
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("@/assets/order_status1.png");
  background-size: 100% 100%;
}

::v-deep .el-icon-picture:before {
  display: block;
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("@/assets/order_status2.png");
  background-size: 100% 100%;
}

::v-deep .el-step__title.is-finish,
::v-deep .el-step__title.is-process,
::v-deep .el-step__title.is-wait {
  color: #666666;
  font-weight: 500;
}

::v-deep .el-step__title {
  font-size: 20px;
  line-height: 28px;
  margin-top: 10px;
}



.left1 {
  width: 200px;
  /* margin-right: 30px; */
}

.four-box {
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); */
  padding: 0 30px 0 60px;
}

.search_txt {
  min-width: 108px;
  height: 44px;
  background: #00BBC2;
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
}

input[type='text'] {
  background: #ffffff;
  border-radius: 0;
  border: none;
  box-sizing: border-box;
  height: 44px;
  width: 468px;
  padding: 0 12px 0 0;
  color: #333333;
  font-size: 14px;
  outline: none;
}

input[type='text']::-webkit-input-placeholder {
  color: #999;
}

input[type='text']:active {
  box-sizing: border-box;
  border: none;
}

.out1 {
  height: 134px;
  padding: 30px 18px;
  background: #EFF7FF;
  box-sizing: border-box;
}

.order11 {
  /* height: 42px; */
  border: 1px solid #EEEEEE;
  line-height: 28px;
  padding: 13px 16px 3px;
}

.order_pro {
  min-height: 160px;
  background: url('@/assets/kuang2.png') no-repeat center center;
  background-size: 100% 100%;
  width: 100%;
  margin: 34px 0 32px;
}

.pro_title {
  font-weight: bold;
  font-size: 18px;
  color: #666666;
  padding-top: 16px;
  /* line-height: 36px; */
}

.pro_title1 {
  font-size: 14px;
  color: #FFFFFF;
  line-height: 28px;
  border-radius: 14px;
  margin-bottom: 10px;
  margin-top: 57px
}

.pro_num {
  font-weight: bold;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 28px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 37px;
  margin: 0 auto;
}

.color-333 {
  color: #333;
}

.pro_item {
  width: 132px;
}

.box2 {
  height: 42px;
  background: #005BAC;
  line-height: 42px;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
  margin-top: 32px;
  margin-bottom: 18px;
}

.od_title2 {
  margin: 13px 0 30px;
  position: relative;
  line-height: 60px;
}

.od_title2 .xian {
  position: absolute;
  margin: auto;
  left: 0;
  bottom: 0;
  width: 101px;
  height: 5px;
  background: #F9BE00;
}

.xian2 {
  position: absolute;
  margin: auto;
  left: 11px;
  top: 12px;
  width: 6px;
  height: 18px;
  background: #F9BE00;
}

.pro_item_act {
  background-color: #009982;
  color: #fff;
}

textarea {
  width: 100%;
  min-height: 78px;
  border: 1px solid #eee;
  padding: 15px;
  font-size: 16px;
  color: #333;
  outline: none;
  box-sizing: border-box;
  font-family: "微软雅黑";
}

textarea::-webkit-input-placeholder {
  color: #999999;
  /* font-family: "微软雅黑"; */
}
</style>